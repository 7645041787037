import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import PatientTable from "../../components/healthcare/patient/PatientTable";
import { fetchPatients, Patient } from "../../api/patientApi";
import Pagination from "../../components/Pagination";
import EditPatientModal from "../../components/healthcare/patient/EditPatientModal";
import PatientButtons from "../../components/healthcare/patient/PatientButtons";

const PatientManagementPage: React.FC = () => {
	const [patients, setPatients] = useState<Patient[]>([]);
	const [totalPatients, setTotalPatients] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const PATIENTS_PER_PAGE = 30;

	useEffect(() => {
		loadPatients();
	}, [currentPage]);

	const loadPatients = async () => {
		setIsLoading(true);
		try {
			const { data, total } = await fetchPatients(currentPage, PATIENTS_PER_PAGE, "");
			setPatients(data);
			setTotalPatients(total); // Set total patients from the total in the API response
			setError(null);
		} catch (err) {
			console.error("Error fetching patients:", err);
			setError("Failed to load patients.");
		} finally {
			setIsLoading(false);
		}
	};

	const SearchPatients = async (search: string) => {
		try{
			const { data, total } = await fetchPatients(currentPage, PATIENTS_PER_PAGE, search);
			setPatients(data);
			setTotalPatients(total);
			setError(null);
		}catch(err){
			console.error("Error searching patients:", err);
			setError("Failed to search patients.");
		}
	};

	const handleEditClick = (patient: Patient) => {
		setSelectedPatient(patient);
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setSelectedPatient(null);
		setIsModalOpen(false);
	};

	const handlePatientUpdate = async () => {
        await loadPatients();
    };

	return (
		<Layout>
			<h1 className="text-2xl font-bold mb-4">Patient Management</h1>
			{isLoading ? (
				<p>Loading patients...</p>
			) : (
				<>
					<PatientButtons searchPatients={SearchPatients} reloadPatients={loadPatients}/>
					<PatientTable patients={patients} onEditClick={handleEditClick} />
					<Pagination currentPage={currentPage} totalItems={totalPatients} itemsPerPage={PATIENTS_PER_PAGE} onPageChange={setCurrentPage} />
				</>
			)}
			{selectedPatient && <EditPatientModal isOpen={isModalOpen} onClose={handleModalClose} patient={selectedPatient} onPatientUpdated={handlePatientUpdate} />}
		</Layout>
	);
};

export default PatientManagementPage;
