/**
 * @file This file contains the patient API functions.
 * @module api/patientApi
 * 
 * There are enpoints that get here from both the auth server and the healthcare server
 * most of these endpoint calls come out of the practitioner healthcare system but somce come from labiq sample management as well
 * 
 */

import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_AUTH_SERVER;
const HEALTHCARE_BASE_URL = process.env.REACT_APP_HEALTHCARE_SERVER;

export interface Patient {
	id: number;
	uuid: string;
	username: string;
	email: string;
	password: string;
	password_confirmation: string;
	first_name: string;
	last_name: string;
	phone: string;
	address: string;
	city: string;
	state: string;
	country: string;
	postal_code: string;
	profile_picture: string;
	date_of_birth: Date;
	gender: string;
	preferences: string;
	is_active: boolean;
	created_at: Date;
	updated_at: Date;
	company: string;
	panel_key: string;
	company_code: string;
	reagents: string;
}

export interface MygeneProgramStatus {
	user_id: number;
	program_elected: string;
	swab_kit_sent: boolean;
	swab_kit_returned: boolean;
	questionnaire_sent: boolean;
	questionnaire_completed: boolean;
	questionnaire_sent_admin: boolean;
	pedigree_chart_filled: boolean;
	pathology_consult_completed: boolean;
	pathology_forms_sent: boolean;
	initial_blood_test_completed: boolean;
	genetic_results_ready: boolean;
	consult_90_minute_booked: boolean;
	consult_90_minute_completed: boolean;
	date_90_minute_consult: Date | null;
	initial_blood_consult_completed: boolean;
	date_initial_blood_consult: Date | null;
	follow_up_blood_test_due: Date | null;
	follow_up_blood_test_completed: boolean;
	follow_up_blood_consult_completed: boolean;
}


///////////////////////////////////////////////////////standard patient routes from the auth server
export const fetchPatients = async (
	page: number,
	perPage: number,
	search?: string
): Promise<{
	data: Patient[];
	total: number;
}> => {
	const response = await axios.post(
		`${API_BASE_URL}/practitioner/getPatient`,
		{
			company: JSON.parse(sessionStorage.getItem("user") || "{}").company,
		},
		{
			params: {
				page,
				per_page: perPage,
				...(search ? { search } : {}),
			},
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		}
	);
	return {
		data: response.data.data,
		total: response.data.pagination.total_items, // Access total_items here
	};
};


export const fetchSinglePatientByUuid = async (
	uuid: string
): Promise<{
	data: Patient;
}> => {
	const response = await axios.get(`${API_BASE_URL}/practitioner/patientSimpleUuid/${uuid}`, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return {
		data: response.data,
	};
};

export const updatePatient = async (patientId: number, patientData: Partial<Patient>) => {
	const response = await axios.put(`${API_BASE_URL}/practitioner/patient/${patientId}`, patientData, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};

export const activatePatient = async (patientId: number) => {
	const response = await axios.put(`${API_BASE_URL}/practitioner/patient/${patientId}/activate`, {}, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};

export const deactivatePatient = async (patientId: number) => {
	const response = await axios.delete(`${API_BASE_URL}/practitioner/patient/${patientId}`, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};


export const createPatient = async (patientData: Partial<Patient>) => {
	const response = await axios.post(`${API_BASE_URL}/practitioner/patient`, patientData, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};

export const assignGeneCodes = async (patientId: number) => {
	const response = await axios.post(`${API_BASE_URL}/practitioner/patient/${patientId}/assign-role`, {
		"role": "gene.codes"
	}, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};

////////////////////////////////////////////// patient sample management
export const fetchPatientsSimple = async (
	page: number,
	perPage: number
): Promise<{
	data: Patient[];
	total: number;
	currentPage: number;
	totalPages: number;
}> => {
	const response = await axios.post(
		`${API_BASE_URL}/practitioner/patientSimple`,
		{
			company: JSON.parse(sessionStorage.getItem("user") || "{}").company,
		},
		{
			params: {
				page,
				per_page: perPage,
			},
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		}
	);

	return {
		data: response.data.data,
		total: response.data.pagination.total_items,
		currentPage: response.data.pagination.current_page,
		totalPages: response.data.pagination.total_pages,
	};
};


export const fetchSinglePatientSimple = async (
	patientEmail: string
): Promise<{
	data: Patient[];
}> => {
	const response = await axios.get(`${API_BASE_URL}/practitioner/patientSimple/${patientEmail}`, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return {
		data: response.data,
	};
};

export const fetchPatientSamples = async (patientEmail: string) => {
	const response = await axios.get(
		`${HEALTHCARE_BASE_URL}/practitioner/patient/gene-samples-all/${patientEmail}`,
		{
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		}
	);
	return response.data;
};

export const fetchSamplesPatient = async (sampleId: string) => {
	const response = await axios.get(
		`${HEALTHCARE_BASE_URL}/practitioner/patient/gene-samples/id/${sampleId}`,
		{
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		}
	);
	return response.data;
}

export const assignSampleToPatient = async (patientEmail: string, sampleId: string) => {
	const response = await axios.post(
		`${HEALTHCARE_BASE_URL}/practitioner/patient/gene-samples`,
		{
			"user_email": patientEmail,
			"gene_sample_id":sampleId,
			"is_active": false,
		},
		{
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		}
	);
	return response.data;
};

//Takes in the id number for the authorised sample out of the user_gene_samples table, not the sample id itself
export const activationOfSampleForPatient = async (isActive: boolean, authUserSampleId: number) => {
	const response = await axios.put(
		`${HEALTHCARE_BASE_URL}/practitioner/patient/gene-samples/${authUserSampleId}`,
		{
			"is_active": isActive,
		},
		{
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		}
	);
	return response.data;
};

export const removeSampleFromPatient = async (patientEmail: string, sampleId: string) => {
	const response = await axios.delete(
		`${HEALTHCARE_BASE_URL}/practitioner/patient/gene-samples/${patientEmail}/${sampleId}`,
		{
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		}
	);
	return response.data;
};




////////////////////////////////////////////////Mygene Program status endpoints in healthcare server
export const fetchPatientProgramStatus = async (
	userId: number
): Promise<{
	data: MygeneProgramStatus;
}> => {
	const response = await axios.get(`${HEALTHCARE_BASE_URL}/mygene/healthcare-program-status/${userId}`, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return {
		data: response.data,
	};
};

export const updatePatientProgramStatus = async (userId: number, status: Partial<MygeneProgramStatus>) => {
    const response = await axios.put(`${HEALTHCARE_BASE_URL}/mygene/healthcare-program-status/${userId}`, status, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return response.data;
};

export const createPatientProgramStatus = async (status: Partial<MygeneProgramStatus>) => {
    const response = await axios.post(`${HEALTHCARE_BASE_URL}/mygene/healthcare-program-status`, status, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return response.data;
};