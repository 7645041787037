import React, { ChangeEvent } from "react";
import { Patient } from "../../../../api/patientApi";
import Pagination from "../../../Pagination";


interface SamplePatientTabProps {
	users: Patient[];
	linkedUser: number;
	setLinkedUser: React.Dispatch<React.SetStateAction<number>>;
	setLinkedUserEmail: React.Dispatch<React.SetStateAction<string>>;
	removeUserFromSample: () => void;
	isSubmitting: boolean;
}

const SamplePatientTab: React.FC<SamplePatientTabProps> = ({ users, linkedUser, setLinkedUser, setLinkedUserEmail, removeUserFromSample, isSubmitting }) => {
    
	
	return (
        <div className="grid grid-cols-1">
            {users.length > 0 ? (
                users.map((user) => (
                    <div key={user.id} className="flex items-center space-x-4 p-2 border-b">
                        <input
                            type="radio"
                            id={`user-${user.id}`}
                            onChange={() => {
                                setLinkedUser(user.id);
                                setLinkedUserEmail(user.email);
                            }}
                            name="linkedUser"
                            value={user.id}
                            className="form-radio"
                            checked={linkedUser === user.id}
                        />
                        <label htmlFor={`user-${user.id}`} className="flex-1">
                            <span className="block text-sm font-medium">
                                {user.first_name} {user.last_name}
                            </span>
                            <span className="block text-xs text-gray-500">{user.email}</span>
                        </label>
                        {linkedUser === user.id && (
                            <button type="button" onClick={() => removeUserFromSample()} disabled={isSubmitting} className={`px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}>
                                {isSubmitting ? "Saving..." : "Remove User Link"}
                            </button>
                        )}
                    </div>
                ))
            ) : (
                <div className="flex items-center space-x-4 p-2 border-b">
                    <p>No linked patients available.</p>
                    <a href="/healthcare/patients" className={`px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600`}>Patient Management</a>
                </div>
            )}
        </div>
    );
};

export default SamplePatientTab;
