import React from "react";
import Layout from "../components/Layout";

const DashboardPage: React.FC = () => {
    return (
        <Layout>
            <div className="dashboard">
                <h1>Welcome to the Dashboard</h1>
                {/* Dashboard content goes here */}
            </div>
        </Layout>
    );
};

export default DashboardPage;
