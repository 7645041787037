import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_AUTH_SERVER;

export interface Application {
	id: number;
	name: string;
	client_id: string;
	client_secret: string;
	redirect_uris: string[];
	logo: string;
	description: string;
	created_at: string;
	updated_at: string;
}

export const createApplication = async (applicationData: any) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/admin/applications`, applicationData, {
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		});
        return response.data;
    } catch (error) {
        throw new Error(`Error creating application: ${error}`);
    }
};

export const getApplications = async (
    page: number,
    perPage: number
): Promise<{
    data: Application[];
    total: number;
}>  => {
    try {
        const response = await axios.get(`${API_BASE_URL}/admin/applications`,{
			params: {
				page,
				per_page: perPage,
			},
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		});
        return {
			data: response.data,
			total: response.data.length,
		};
    } catch (error) {
        throw new Error(`Error fetching applications: ${error}`);
    }
};

export const getApplicationById = async (id: string) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/admin/applications/${id}`,{
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		});
        return response.data;
    } catch (error) {
        throw new Error(`Error fetching application with id ${id}: ${error}`);
    }
};

export const updateApplication = async (id: string, applicationData: any) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/admin/applications/${id}`, applicationData, {
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		});
        return response.data;
    } catch (error) {
        throw new Error(`Error updating application with id ${id}: ${error}`);
    }
};

export const deleteApplication = async (id: string) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/admin/applications/${id}`, {
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		});
        return response.data;
    } catch (error) {
        throw new Error(`Error deleting application with id ${id}: ${error}`);
    }
};
