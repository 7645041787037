import { useState, useEffect } from "react";
import React from "react";
import { fetchPatientSamples, removeSampleFromPatient, assignSampleToPatient } from "../../../../api/patientApi";
import { createOrUpdateSamplesPanels } from "../../../../api/geneCodesApi";
import { fetchSamples, Sample } from "../../../../api/labiqApi";

//import modal components
import CreateSampleModal from "./CreateSampleSimpleModal";
import GeneCodesModal from "../modalComponents/GeneCodesModal";

interface SampleTabProps {
    patient: any;
    isSubmitting: boolean;
    setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
    setError: React.Dispatch<React.SetStateAction<string>>;
}

const SampleTab: React.FC<SampleTabProps> = ({ patient, isSubmitting, setIsSubmitting, setError }) => {
    const [samples, setSamples] = useState<any[]>([]);
    const [isCreateSampleModalOpen, setIsCreateSampleModalOpen] = useState<boolean>(false);
    const [isGeneCodesModalOpen, setIsGeneCodesModalOpen] = useState<boolean>(false);
    const [selectedSample, setSelectedSample] = useState<number>(0);
    const [selectedSampleActive, setSelectedSampleActive] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState<any[]>([]);

    useEffect(() => {
        if (patient.id !== 0) {
            fetchPatientSample();
        }
    }, [patient.id]);

    const fetchPatientSample = async () => {
        try {
            const samples = await fetchPatientSamples(patient.email);
            setSamples(samples);
        } catch (error) {
            console.error("Error fetching samples:", error);
        }
    };

    const removePatientFromSample = async (sampleClientID: string, sampleId: number) => {
        setIsSubmitting(true);
        try {
            await removeSampleFromPatient(patient.email, sampleClientID);
            await createOrUpdateSamplesPanels(sampleId, []);
            const updatedSamples = await fetchPatientSamples(patient.email);
            setSamples(updatedSamples);
        } catch (err) {
            console.error("Error removing patient from sample:", err);
            setError("Failed to remove patient from sample.");
        } finally {
            setIsSubmitting(false);
        }
    };

	const assignSample = async (sampleClientId: string) => {
		try{
			await assignSampleToPatient(patient.email, sampleClientId)
			await fetchPatientSample();
		} catch (err){
			console.log(err);
		}
	}

    const openGeneCodesModal = async (sampleId: number, isActive: boolean) => {
        await setSelectedSample(sampleId);
        await setSelectedSampleActive(isActive);
        setIsGeneCodesModalOpen(true);
    };

    const closeGeneCodesModal = async () => {
        setIsGeneCodesModalOpen(false);
        fetchPatientSample();
    };

    const searchSamples = async (term: string) => {
        setError("");
        try {
            const userInfo = JSON.parse(sessionStorage.getItem("user") || "{}");
            const company = userInfo.company;

            const { data } = await fetchSamples(1, 20, company, "sampleID", "desc", term);
            setSearchResults(data); // Set the search results for the dropdown
        } catch (err) {
            console.error("Error fetching samples:", err);
            setError("Failed to load samples.");
        }
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value;
        setSearchTerm(term);
        if (term) {
            searchSamples(term);
        } else {
            setSearchResults([]); // Clear results when input is empty
        }
    };

    return (
        <div className="grid grid-cols-1 relative">
            <div className="flex justify-end mb-4">
                <input type="text" placeholder="Search existing samples..." value={searchTerm} onChange={handleSearchChange} className="border px-4 py-2 rounded mr-10" />
                {/* Search results dropdown */}
                {searchResults.length > 0 && (
                    <div className="absolute mt-1 w-full max-w-xs bg-white border border-gray-200 rounded shadow-lg z-10" style={{marginTop: '40px'}}>
                        {searchResults.map((sample) => (
                            <div
                                key={sample.client_sampleID}
                                onClick={() => {
                                    setSearchTerm("");
                                    setSearchResults([]);
									assignSample(sample.client_sampleID);
                                }}
                                className="p-2 hover:bg-gray-100 cursor-pointer"
                            >
                                <span>{sample.client_sampleID}</span>
                            </div>
                        ))}
                    </div>
                )}
                <button type="button" onClick={() => setIsCreateSampleModalOpen(true)} className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">
                    Create Sample
                </button>
            </div>

            {/* map the samples that the user has assigned to them */}
            {samples.length > 0 ? (
                samples.map((sample) => (
                    <div key={sample.gene_sample_id || sample.sample_id} className="flex items-center space-x-4 p-2 border-b">
                        <label htmlFor={`patient-${patient.id}`} className="flex-1">
                            <span className="block text-sm font-medium">{sample.gene_sample_id || sample.sample_id}</span>
                            <span className="block text-xs text-gray-500">{sample.gene_sample_id || sample.sample_id}</span>
                        </label>
                        <button
                            type="button"
                            onClick={() => openGeneCodesModal(sample.id, sample.is_active)}
                            disabled={isSubmitting}
                            className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
                        >
                            {isSubmitting ? "Processing..." : "Gene.Codes"}
                        </button>

                        <button
                            type="button"
                            onClick={() => (window.location.href = `/labiq/sample-tracking/${sample.gene_sample_id || sample.sample_id}`)}
                            disabled={isSubmitting}
                            className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
                        >
                            {isSubmitting ? "Processing..." : "View Sample"}
                        </button>
                        <button
                            type="button"
                            onClick={() => removePatientFromSample(sample.gene_sample_id || sample.sample_id, sample.id)}
                            disabled={isSubmitting}
                            className={`px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
                        >
                            {isSubmitting ? "Processing..." : "Remove Sample From Patient"}
                        </button>
                    </div>
                ))
            ) : (
                <div>
                    <p>No samples found</p>
                </div>
            )}

            {/* modals for the users samples */}
            <CreateSampleModal isOpen={isCreateSampleModalOpen} onClose={() => setIsCreateSampleModalOpen(false)} setSamples={setSamples} patientEmail={patient.email} />
            {isGeneCodesModalOpen && <GeneCodesModal isOpen={isGeneCodesModalOpen} onClose={() => closeGeneCodesModal()} sampleId={selectedSample} isActive={selectedSampleActive} setIsActive={setSelectedSampleActive} patientId={patient.id} />}
        </div>
    );
};

export default SampleTab;
