import React, { useState } from 'react';
import { Role } from "../../api/rolesApi";
import EditRoleModal from "../roles/EditRoleModal";

const createEmptyRole = (): Role => ({
	id: 0,
	guard_name: "api",
	name: "",
	created_at: "",
	updated_at: "",
	pivot: {
		model_type: "",
		model_id: 0,
		role_id: 0,
	},
});

const RoleButtons: React.FC = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [newRole, setNewRole] = useState<Role>(createEmptyRole());

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	const handleCreateRole = () => {
		handleModalClose();
	};

	return (
		<div>
			<button onClick={handleOpenModal} className='px-4 py-2 bg-gray-200 rounded opacity-100 hover:bg-gray-300'>Add Role</button>

			{/* Modal */}
			{isModalOpen && (
				<EditRoleModal isOpen={isModalOpen} onClose={handleModalClose} role={newRole} onRoleUpdated={handleCreateRole} />
			)}
		</div>
	);
};

export default RoleButtons;
