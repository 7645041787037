// src/components/inventory/StockAssignmentTable.tsx

import React from "react";
import { StockAssignment } from "../../../api/stockAssignmentApi";

interface StockAssignmentTableProps {
    assignments: StockAssignment[];
    onEditClick: (assignment: StockAssignment) => void;
    onDeleteClick: (assignment: StockAssignment) => void;
}

const StockAssignmentTable: React.FC<StockAssignmentTableProps> = ({ assignments, onEditClick, onDeleteClick }) => {
    if (!assignments || assignments.length === 0) {
        return <p>No assignments available.</p>;
    }

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white border">
                <thead>
                    <tr>
                        <th className="py-2 px-3 border-b">Assignment ID</th>
                        <th className="py-2 px-3 border-b">Assignee Type</th>
                        <th className="py-2 px-3 border-b">Assignee</th>
                        <th className="py-2 px-3 border-b">Quantity Assigned</th>
                        <th className="py-2 px-3 border-b">Status</th>
                        <th className="py-2 px-3 border-b">Assigned At</th>
                        <th className="py-2 px-3 border-b">Notes</th>
                        <th className="py-2 px-3 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {assignments.map((assignment) => (
                        <tr key={assignment.assignment_id} className="text-center">
                            <td className="py-2 px-3 border-b">{assignment.assignment_id}</td>
                            <td className="py-2 px-3 border-b">{assignment.assignee_type}</td>
                            <td className="py-2 px-3 border-b">{assignment.assignee_id}</td>
                            <td className="py-2 px-3 border-b">{assignment.quantity_assigned}</td>
                            <td className="py-2 px-3 border-b">{assignment.status}</td>
                            <td className="py-2 px-3 border-b">{assignment.assigned_at ? new Date(assignment.assigned_at).toLocaleString() : "-"}</td>
                            <td className="py-2 px-3 border-b">{assignment.notes || "-"}</td>
                            <td className="py-2 px-3 border-b">
                                <button onClick={() => onEditClick(assignment)} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 mr-2">
                                    Edit
                                </button>
                                <button onClick={() => onDeleteClick(assignment)} className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StockAssignmentTable;
