// src/components/inventory/StockModal.tsx

import React, { useState, useEffect } from "react";
import { Stock } from "../../../api/stockApi";

interface StockModalProps {
    isOpen: boolean;
    onClose: () => void;
    stock: Stock | null;
    onSave: (stockData: Partial<Stock>) => void;
    productId?: number;
}

const StockModal: React.FC<StockModalProps> = ({ isOpen, onClose, stock, onSave, productId }) => {
    const [formData, setFormData] = useState<Partial<Stock>>({ product_id: productId });

    useEffect(() => {
        setFormData(stock || { product_id: productId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stock]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const numberValue = value === "" ? undefined : parseInt(value, 10);
        setFormData((prevData) => ({ ...prevData, [name]: numberValue }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSave(formData);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-md w-full max-w-lg">
                <h2 className="text-xl font-bold mb-4">{stock ? "Edit Stock Entry" : "Add Stock Entry"}</h2>
                <form onSubmit={handleSubmit}>
                    {/* Product ID */}
                    {!productId && (
                        <div className="mb-4">
                            <label className="block text-gray-700 font-medium mb-1">Product ID</label>
                            <input name="product_id" type="number" value={formData.product_id || ""} onChange={handleNumberChange} required className="w-full border px-3 py-2 rounded" />
                        </div>
                    )}
                    {/* Quantity */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Quantity</label>
                        <input name="quantity" type="number" value={formData.quantity !== undefined ? formData.quantity : ""} onChange={handleNumberChange} required className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* Location */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Location</label>
                        <input name="location" type="text" value={formData.location || ""} onChange={handleChange} className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* Batch Number */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Batch Number</label>
                        <input name="batch_number" type="text" value={formData.batch_number || ""} onChange={handleChange} className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* Expiry Date */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Expiry Date</label>
                        <input name="expiry_date" type="date" value={formData.expiry_date || ""} onChange={handleChange} className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* Status */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Status</label>
                        <select name="status" value={formData.status || "available"} onChange={handleChange} className="w-full border px-3 py-2 rounded">
                            <option value="available">Available</option>
                            <option value="reserved">Reserved</option>
                            {/* Add more statuses as needed */}
                        </select>
                    </div>
                    {/* Buttons */}
                    <div className="flex justify-end">
                        <button type="button" onClick={onClose} className="mr-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">
                            Cancel
                        </button>
                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                            {stock ? "Update" : "Create"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default StockModal;
