import React, { useState, useEffect } from "react";
import { createOrUpdateSamplesPanels, getSamplesPanels, getPanels, Panel } from "../../../../api/geneCodesApi";
import { assignGeneCodes, activationOfSampleForPatient } from "../../../../api/patientApi";

interface GeneCodesModalProps {
    isOpen: boolean;
    onClose: () => void;
    sampleId: number;
    isActive: boolean;
    setIsActive: React.Dispatch<boolean>;
    patientId: number;
}

const GeneCodesModal: React.FC<GeneCodesModalProps> = ({ isOpen, sampleId, onClose, isActive, setIsActive, patientId }) => {
    const [panelList, setPanelList] = useState<Panel[]>([]);
    const [selectedPanels, setSelectedPanels] = useState<number[]>([]);
    const [error, setError] = useState<any>("");
    const [statusMessage, setStatusMessage] = useState<string | null>(null); // State for success/failure messages

    useEffect(() => {
        const fetchData = async () => {
            try {
                const panels = await getPanels();
                setPanelList(panels);

                const samplePanels = await getSamplesPanels(sampleId);
                setSelectedPanels(samplePanels.flat());
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [sampleId]);

    const setStatus = () => {
        setIsActive(!isActive);
    };

    const handleCheckboxChange = (panelId: number, isChecked: boolean) => {
        setSelectedPanels((prevSelectedPanels) =>
            isChecked ? [...prevSelectedPanels, panelId] : prevSelectedPanels.filter((id) => id !== panelId)
        );
    };

    const handleSelectAll = () => {
        if (selectedPanels.length === panelList.length) {
            setSelectedPanels([]);
        } else {
            setSelectedPanels(panelList.map(panel => panel.id_panels));
        }
    };

    const handleSubmit = async () => {
        try {
            await createOrUpdateSamplesPanels(sampleId, selectedPanels);
            await activationOfSampleForPatient(isActive, sampleId);

            if (isActive) {
                await assignGeneCodes(patientId);
            }

            setStatusMessage("Update successful!");
            setError(null);
        } catch (err) {
            console.error(err);
            setError("An error occurred while updating.");
            setStatusMessage(null);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-md w-full max-w-xl">
                <div className="p-6">
                    <div className="pb-5">
                        <label htmlFor="activation" className="block text-sm">
                            Activation Status
                        </label>
                        <button onClick={setStatus} className={`${isActive ? "bg-blue-500" : "bg-gray-300"} relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-300`}>
                            <span className={`${isActive ? "translate-x-6" : "translate-x-1"} inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-300`} />
                        </button>
                    </div>
                    <div>
                        <label htmlFor="categories" className="block text-sm pb-3">
                            Available Categories
                        </label>
                        <button onClick={handleSelectAll} className="mb-2 bg-gray-300 text-gray-700 px-2 py-1 rounded">
                            {selectedPanels.length === panelList.length ? "Deselect All" : "Select All"}
                        </button>
                        <div className="grid grid-cols-2">
                            {panelList.map((panel) => (
                                <div key={panel.id_panels} className="flex items-center mb-2">
                                    <input
                                        type="checkbox"
                                        id={panel.name_panel}
                                        value={panel.name_panel}
                                        checked={selectedPanels.includes(panel.id_panels)}
                                        onChange={(e) => handleCheckboxChange(panel.id_panels, e.target.checked)}
                                        className="mr-2"
                                    />
                                    <label htmlFor={panel.name_panel} className="text-gray-700">
                                        {panel.name_panel}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Display error or success message */}
                    {statusMessage && <p className="text-green-500 mt-4">{statusMessage}</p>}
                    {error && <p className="text-red-500 mt-4">{error}</p>}

                    <div className="flex justify-end mt-4">
                        <button type="button" onClick={onClose} className="mr-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">
                            Cancel
                        </button>
                        <button type="button" onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GeneCodesModal;
