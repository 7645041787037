import React, { useEffect } from "react";
import { MygeneProgramStatus } from "../../../../api/patientApi";

interface MygeneProgramStatusTabProps {
    status: MygeneProgramStatus;
    setStatus: React.Dispatch<React.SetStateAction<MygeneProgramStatus | undefined>>;
}

const MygeneProgramStatusTab: React.FC<MygeneProgramStatusTabProps> = ({ status, setStatus }) => {
    useEffect(() => {

    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, type, checked, value } = e.target;
        setStatus((prevStatus) => ({
            ...prevStatus!,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    return (
        <div className="grid grid-cols-3 gap-6 p-5">
            {Object.keys(status).map((key) =>
                !["id", "user_id", "created_at", "updated_at"].includes(key) ? (
                    <div key={key} className="flex items-center justify-between">
                        <label className="text-sm font-medium text-gray-700 capitalize mr-4">{key.replace(/_/g, " ")}</label>
                        {typeof status[key as keyof MygeneProgramStatus] === "boolean" ? (
                            <button
                                onClick={() =>
                                    setStatus((prev) =>
                                        prev
                                            ? {
                                                  ...prev,
                                                  [key]: !prev[key as keyof MygeneProgramStatus],
                                              }
                                            : prev
                                    )
                                }
                                className={`${status[key as keyof MygeneProgramStatus] ? "bg-blue-500" : "bg-gray-300"} relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-300`}
                            >
                                <span className={`${status[key as keyof MygeneProgramStatus] ? "translate-x-6" : "translate-x-1"} inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-300`} />
                            </button>
                        ) : key.includes("date") || key.includes("test_due") ? (
                            <input
                                type="date"
                                name={key}
                                value={
                                    typeof status[key as keyof MygeneProgramStatus] === "string" || typeof status[key as keyof MygeneProgramStatus] === "number"
                                        ? status[key as keyof MygeneProgramStatus]?.toString() || ""
                                        : status[key as keyof MygeneProgramStatus] instanceof Date
                                        ? (status[key as keyof MygeneProgramStatus] as Date).toISOString().split("T")[0]
                                        : ""
                                }
                                onChange={handleChange}
                                className="w-full max-w-xs p-2 border border-gray-300 rounded-md shadow-sm text-gray-700 text-sm"
                            />
                        ) : (
                            <input
                                type="text"
                                name={key}
                                value={
                                    typeof status[key as keyof MygeneProgramStatus] === "number"
                                        ? status[key as keyof MygeneProgramStatus]?.toString() || ""
                                        : typeof status[key as keyof MygeneProgramStatus] === "boolean"
                                        ? ""
                                        : (status[key as keyof MygeneProgramStatus] as string | number | undefined) || ""
                                }
                                onChange={handleChange}
                                className="w-full max-w-xs p-2 border border-gray-300 rounded-md shadow-sm text-gray-700 text-sm"
                                placeholder="Enter value"
                            />
                        )}
                    </div>
                ) : null
            )}
        </div>
    );
};

export default MygeneProgramStatusTab;
