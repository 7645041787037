import React from 'react';

interface NavButtonsProps {
	activeTab: string;
	setActiveTab: (tab: string) => void;
	patient: any;
}

const NavButtons: React.FC<NavButtonsProps> = ({ activeTab, setActiveTab, patient }) => {
    return (
        <div>
            <button onClick={() => setActiveTab("Personal")} className={`pb-2 mr-4 ${activeTab === "Personal" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                Personal Info
            </button>

            {JSON.parse(sessionStorage.getItem("user") || "{}").roles.some((role: any) => role.name === "Lab Management" || role.name === "Practitioner") && patient.id !== 0 && (
                <button onClick={() => setActiveTab("Samples")} className={`pb-2 mr-4 ${activeTab === "Samples" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                    Samples
                </button>
            )}

            {JSON.parse(sessionStorage.getItem("user") || "{}").company == "Mygene" && (
                <button onClick={() => setActiveTab("MygeneProgramStatus")} className={`pb-2 ${activeTab === "MygeneProgramStatus" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                    Mygene Program Status's
                </button>
            )}
        </div>
    );
};

export default NavButtons;