// src/api/stockApi.ts

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_COMMERCE_SERVER;

export interface Stock {
  stock_id: number;
  product_id: number;
  quantity: number;
  location?: string;
  batch_number?: string;
  expiry_date?: string;
  status?: string;
  created_at?: string;
  updated_at?: string;
}

// Fetch a list of stock entries with pagination and optional filters
export const fetchStockEntries = async (
  page: number = 1,
  perPage: number = 20,
  filters?: { product_id?: number; status?: string }
): Promise<{ data: Stock[]; total: number }> => {
  const response = await axios.get(`${API_BASE_URL}/stock`, {
    params: {
      page,
      per_page: perPage,
      ...filters,
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('apiKey')}`,
    },
  });
  return {
    data: response.data.data,
    total: response.data.total,
  };
};

// Fetch details of a specific stock entry
export const fetchStockEntry = async (stock_id: number): Promise<Stock> => {
  const response = await axios.get(`${API_BASE_URL}/stock/${stock_id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('apiKey')}`,
    },
  });
  return response.data;
};

// Create a new stock entry
export const createStockEntry = async (stockData: Partial<Stock>): Promise<Stock> => {
  const response = await axios.post(`${API_BASE_URL}/stock`, stockData, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('apiKey')}`,
    },
  });
  return response.data;
};

// Update an existing stock entry
export const updateStockEntry = async (
  stock_id: number,
  stockData: Partial<Stock>
): Promise<Stock> => {
  const response = await axios.put(`${API_BASE_URL}/stock/${stock_id}`, stockData, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('apiKey')}`,
    },
  });
  return response.data;
};

// Delete a stock entry
export const deleteStockEntry = async (stock_id: number): Promise<void> => {
  await axios.delete(`${API_BASE_URL}/stock/${stock_id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('apiKey')}`,
    },
  });
};
