// src/pages/orders/SalesOrderManagementPage.tsx

import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";

const OrderingOverviewPage: React.FC = () => {
    

    return (
        <Layout>
            <h1 className="text-2xl font-bold mb-4">Sales Order Management</h1>
            <div className="mb-4">
				<button onClick={() => window.location.href = '/inventory/ordering/sales-orders'} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
					Sales Orders
				</button>
				<button onClick={() => window.location.href = '/inventory/ordering/purchase-orders'} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
					Purchase Orders
				</button>
            </div>
            
        </Layout>
    );
};

export default OrderingOverviewPage;
