

import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import ApplicationTable from "../../components/application/AppTable";
import { getApplications, Application } from "../../api/appApi";
import Pagination from "../../components/Pagination";
import EditApplicationModal from "../../components/application/EditAppModal";
import ApplicationButtons from "../../components/application/AppButtons";

const ApplicationManagementPage: React.FC = () => {
	//construct the variables for
	const [applications, setApplications] = useState<Application[]>([]);
	const [totalApplications, setTotalApplications] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedApplication, setSelectedApplication] = useState<Application | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const APPLICATIONS_PER_PAGE = 30;

	//load the page and set the state
	useEffect(() => {
		//on page load, call the api to get the application data and set the variables
		const loadApplications = async () => {
			setIsLoading(true);
			try {
				const { data, total } = await getApplications(currentPage, APPLICATIONS_PER_PAGE);
				setApplications(data);
				setTotalApplications(total);
				setError(null);
			} catch (err) {
				console.error("Error fetching applications:", err);
				setError("Failed to load applications.");
			} finally {
				setIsLoading(false);
			}
		};

		loadApplications();
	}, [currentPage]);

	//page functions
	const handleEditClick = (application: Application) => {
		setSelectedApplication(application);
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setSelectedApplication(null);
		setIsModalOpen(false);
	};

	const handleApplicationUpdate = async () => {
			try {
				// Update application in the list
				setIsLoading(true);
				const { data, total } = await getApplications(currentPage, APPLICATIONS_PER_PAGE);
				setApplications(data);
				setTotalApplications(total);
				setError(null);
			} catch (err) {
				console.error("Error fetching updated applications:", err);
				setError("Failed to load updated applications.");
			} finally {
				setIsLoading(false);
				handleModalClose();
			}
		};

	return (
		<Layout>
			<h1 className="text-2xl font-bold mb-4">Application Management</h1>
			{isLoading ? (
				<p>Loading applications...</p>
			) : (
				<>
					<ApplicationButtons />
					<ApplicationTable apps={applications} onEditClick={handleEditClick} />
					<Pagination
						currentPage={currentPage}
						totalItems={totalApplications}
						itemsPerPage={APPLICATIONS_PER_PAGE}
						onPageChange={setCurrentPage}
					/>
				</>
			)}
			{selectedApplication && (
				<EditApplicationModal
					isOpen={isModalOpen}
					onClose={handleModalClose}
					app={selectedApplication}
					onAppUpdated={handleApplicationUpdate}
				/>
			)}
		</Layout>
	);
};

export default ApplicationManagementPage;
