// src/components/icons/LogoutIcon.tsx
import React from "react";

const LogoutIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 12H3m12 0l-4-4m4 4l-4 4"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"
    />
  </svg>
);

export default LogoutIcon;
