import React, { Fragment, useState, useEffect } from "react";
import { Dialog, DialogTitle, Transition } from "@headlessui/react";
import { submitBatch, updateBatch, createBatch, fetchReagentList, fetchBatch, Batch } from "../../api/labiqApi";
import { fetchCompanies, Company } from "../../api/companyApi";


interface BatchModalProps {
    isOpen: boolean;
    onClose: () => void;
    batch_id: number;
    onFormSubmission: () => void;
}

const BatchModal: React.FC<BatchModalProps> = ({ isOpen, onClose, batch_id, onFormSubmission }) => {
    const [formData, setFormData] = useState<Partial<Batch>>({});
    const [companies, setCompanies] = useState<Company[]>([]);
	const [reagentList, setReagentList] = useState<string[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [activeTab, setActiveTab] = useState("Details");

    //Init on the first render
    useEffect(() => {
        const getCompanies = async () => {
            try {
                const data = await fetchCompanies();
                setCompanies(data);
            } catch (err) {
                console.error("Error fetching companies:", err);
                setError("Failed to load companies.");
            }
        };
		const fetchReagents = async () => {
            try {
                const reagents = await fetchReagentList();
                setReagentList(reagents);
            } catch (err) {
                console.error("Error fetching reagents:", err);
                setError("Failed to load reagents.");
            }
        };
        if (isOpen) {
            try {
				if (batch_id !== 0){
                	getBatchDetails();
				}
                const userCompany = JSON.parse(sessionStorage.getItem("user") || "{}").company;
                if (userCompany === "Mygene" || userCompany === "DNAIQ") {
                    getCompanies();
                } else {
                    setCompanies([userCompany]);
                }
				fetchReagents();
            } catch (err) {
                console.error("Error fetching batch details:", err);
                setError("Failed to load batch details.");
            }
        }
    }, [isOpen, batch_id]);

    //get the batch details
    const getBatchDetails = async () => {
        try {
            const response = await fetchBatch(batch_id);
            const batchData: Partial<Batch> = {
                batch_id: response.batch_id,
                batch_client_id: response.batch_client_id,
                batch_delivery_option: response.batch_delivery_option,
                batch_tracking_no: response.batch_tracking_no,
                batch_vendor: response.batch_vendor,
                batch_create_date: response.batch_create_date,
                batch_submission: response.batch_submission,
                batch_user_id: response.batch_user_id,
                batch_company: response.batch_company,
                batch_sample_count: response.batch_sample_count,
                samples: response.samples,
            };
            setFormData(batchData);
        } catch (err) {
            console.error("Error fetching batch details:", err);
            setError("Failed to load batch details.");
        }
    };

    //handle changes to the form
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    //handle changes to the select element
    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    //handle changes to the textarea element
    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    //handle changes to the sample fields
    const handleSampleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value } = e.target;
        setFormData((prev) => {
            const samples = [...(prev.samples || [])];
            samples[index] = { ...samples[index], [name]: value };
            return { ...prev, samples };
        });
    };

	const handleSampleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
		const { name, value } = e.target;
		setFormData((prev) => {
			const samples = [...(prev.samples || [])];
			samples[index] = { ...samples[index], [name.replace(`_${index}`, "")]: value };
			return { ...prev, samples };
		});
	};

	const handleRemoveSample = (index: number) => {
		setFormData((prev) => {
			const samples = [...(prev.samples || [])];
			samples.splice(index, 1);
			return { ...prev, samples };
		});
	};

	const handleAddSample = () => {
        setFormData((prev) => ({
            ...prev,
            samples: [
                ...(prev.samples || []),
                {
                    bs_id: 0,
                    bs_batch_id: batch_id,
                    bs_sample_id: "",
                    bs_sequence: "",
                    bs_test_type: [],
                    bs_collection_type: "",
                },
            ],
        }));
    };

    //handle the form submission to update the user details
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            //submit the batch details
			
			if (batch_id === 0) {
				await createBatch(formData as Batch);
				onFormSubmission();
				onClose();
			} else {
				if (formData.batch_delivery_option === 0) {
					delete formData.batch_tracking_no;
					delete formData.batch_vendor;
				}
				await updateBatch(batch_id, formData as Batch);
				onFormSubmission();
			}
			
        } catch (err : any) {
            console.error("Error creating/updating batch:", err);
            setError("Failed to create/update Batch, " + err.message);
        } finally {
            setIsSubmitting(false);
        }
    };

	const submitBatchClick = async () => {
		setIsSubmitting(true);
        try {
            //submit the batch details
			if (batch_id === 0) {
				await createBatch(formData as Batch);
			} else {
				if (formData.batch_delivery_option === 0) {
					delete formData.batch_tracking_no;
					delete formData.batch_vendor;
				}
				await updateBatch(batch_id, formData as Batch);
				
			}
			submitBatch(batch_id);
			getBatchDetails();
			onFormSubmission();
        } catch (err : any) {
            console.error("Error submitting batch:", err);
            setError("Failed to Create Batch, " + err.message);
        } finally {
			getBatchDetails();
            setIsSubmitting(false);
        }
	}

	

    
	
    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
                <div className="flex items-center justify-center min-h-screen px-4">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black opacity-30" />
                    </Transition.Child>

                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                        <div className="bg-white rounded p-6 z-20 w-1/2">
                            {/* Modal heading */}
                            <DialogTitle className="text-xl font-semibold mb-4">{batch_id === 0 ? "Create New Batch" : `Edit Batch ${formData.batch_client_id}`}</DialogTitle>

                            {/* modal tabs, uses activetab useState react hook to transition state of the tab */}
                            <div className="flex space-x-4 mb-6 border-b">
                                <button onClick={() => setActiveTab("Details")} className={`pb-2 ${activeTab === "Details" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                    Batch Details
                                </button>
                                <button onClick={() => setActiveTab("Samples")} className={`pb-2 ${activeTab === "Samples" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                    Samples
                                </button>
                                {formData.batch_submission !== 0 && (
                                    <button onClick={() => setActiveTab("Tracking")} className={`pb-2 ${activeTab === "Tracking" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                        Tracking
                                    </button>
                                )}
                                <button onClick={() => setActiveTab("Other")} className={`pb-2 ${activeTab === "Other" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                    Other
                                </button>
                            </div>

                            <form onSubmit={handleSubmit} className="space-y- ">
                                {/* Details info active tab */}
                                {activeTab === "Details" && (
                                    <div className="grid grid-cols-1">
                                        {/* col 1 */}
                                        <div className="p-6">
                                            <div className="p-2">
                                                <label htmlFor="batch_client_id" className="block text-sm">
                                                    Batch ID
                                                </label>
                                                <input
                                                    type="text"
                                                    id="batch_client_id"
                                                    name="batch_client_id"
                                                    value={formData.batch_client_id || ""}
                                                    onChange={handleChange}
                                                    placeholder="Enter batch ID"
                                                    className="w-full p-2 border rounded"
                                                    required
                                                />
                                            </div>
                                            <div className="p-2">
                                                <label htmlFor="batch_company" className="block text-sm">
                                                    Client
                                                </label>
                                                <select id="batch_company" name="batch_company" value={formData.batch_company || ""} onChange={handleSelectChange} className="w-full p-2 border rounded" required>
                                                    <option value="" disabled>
                                                        Select Company
                                                    </option>
                                                    {companies.map((company) => (
                                                        <option key={company.name} value={company.name}>
                                                            {company.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="p-2">
                                                <label htmlFor="batch_delivery_option" className="block text-sm">
                                                    Delivery Options
                                                </label>
                                                <select id="batch_delivery_option" name="batch_delivery_option" value={formData.batch_delivery_option?.toString() || ""} onChange={handleSelectChange} className="w-full p-2 border rounded" required>
                                                    <option value="" disabled>
                                                        Select Delivery Option
                                                    </option>
                                                    <option value="0">Internal</option>
                                                    <option value="1">Courier</option>
                                                </select>
                                            </div>
                                            {formData.batch_delivery_option?.toString() === "1" && (
                                                <div>
                                                    <div className="p-2">
                                                        <label htmlFor="batch_tracking_no" className="block text-sm">
                                                            Tracking Number
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="batch_tracking_no"
                                                            name="batch_tracking_no"
                                                            value={formData.batch_tracking_no || ""}
                                                            onChange={handleChange}
                                                            placeholder="Enter tracking number"
                                                            className="w-full p-2 border rounded"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="p-2">
                                                        <label htmlFor="batch_vendor" className="block text-sm">
                                                            Delivery Vendor
                                                        </label>
                                                        <select id="batch_vendor" name="batch_vendor" value={formData.batch_vendor || ""} onChange={handleSelectChange} className="w-full p-2 border rounded">
                                                            <option value="" disabled>
                                                                Select Delivery Vendor
                                                            </option>
                                                            <option value="fedex">FedEx</option>
                                                            <option value="ups">UPS</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {/* Samples active tab */}
                                {activeTab === "Samples" && (
                                    <div className="grid grid-cols-1">
                                        {/* col 1 */}
                                        <div className="p-6">
                                            {formData.samples?.map((sample, index) => (
                                                <div key={index} className="p-2 border rounded mb-2 flex flex-wrap">
                                                    <div className="p-2 w-full sm:w-1/2">
                                                        <label htmlFor={`bs_sample_id_${index}`} className="block text-sm">
                                                            Sample ID
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id={`bs_sample_id_${index}`}
                                                            name="bs_sample_id"
                                                            value={sample.bs_sample_id || ""}
                                                            onChange={(e) => handleSampleChange(e, index)}
                                                            placeholder="Enter Sample ID"
                                                            className="w-full p-2 border rounded"
                                                        />
                                                    </div>
                                                    <div className="p-2 w-full sm:w-1/2">
                                                        <label htmlFor={`bs_sequence_${index}`} className="block text-sm">
                                                            Sequence
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id={`bs_sequence_${index}`}
                                                            name="bs_sequence"
                                                            value={sample.bs_sequence || ""}
                                                            onChange={(e) => handleSampleChange(e, index)}
                                                            placeholder="Enter Sequence"
                                                            className="w-full p-2 border rounded"
                                                        />
                                                    </div>
                                                    <div className="p-2 w-full sm:w-1/2">
                                                        <label htmlFor={`bs_test_type_${index}`} className="block text-sm">
                                                            Test Type
                                                        </label>

                                                        <div className="flex flex-wrap">
                                                            {reagentList.map((reagent) => (
                                                                <label key={reagent} className="flex items-center mr-4 mb-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        name={`bs_test_type_${index}`}
                                                                        value={reagent}
                                                                        checked={sample.bs_test_type?.includes(reagent) || false}
                                                                        onChange={(e) => {
                                                                            const { checked, value } = e.target;
                                                                            setFormData((prev) => {
                                                                                const samples = [...(prev.samples || [])];
                                                                                const testTypes = samples[index].bs_test_type || [];
                                                                                if (checked) {
                                                                                    testTypes.push(value);
                                                                                } else {
                                                                                    const idx = testTypes.indexOf(value);
                                                                                    if (idx > -1) {
                                                                                        testTypes.splice(idx, 1);
                                                                                    }
                                                                                }
                                                                                samples[index] = { ...samples[index], bs_test_type: testTypes };
                                                                                return { ...prev, samples };
                                                                            });
                                                                        }}
                                                                        className="mr-2"
                                                                    />
                                                                    {reagent}
                                                                </label>
                                                            ))}
                                                        </div>
                                                    </div>

                                                    <div className="p-2 w-full sm:w-1/2">
                                                        <label htmlFor={`bs_collection_type_${index}`} className="block text-sm">
                                                            Collection Type
                                                        </label>
                                                        <select
                                                            id={`bs_collection_type_${index}`}
                                                            name={`bs_collection_type_${index}`}
                                                            value={sample.bs_collection_type || ""}
                                                            onChange={(e) => handleSampleSelectChange(e, index)}
                                                            className="w-full p-2 border rounded"
                                                        >
                                                            <option value="" disabled>
                                                                Select Collection Type
                                                            </option>
                                                            <option value="copan swab">Copan Swab</option>
                                                            <option value="buccal swab">Buccal Swab</option>
                                                            <option value="DNAtape">DNAtape</option>
                                                            <option value="OraGene saliva">OraGene saliva</option>
                                                        </select>
                                                    </div>
                                                    <div className="p-2 w-full sm:w-1/2 flex items-end space-x-2">
                                                        <button type="button" onClick={() => handleRemoveSample(index)} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
                                                            Remove Sample
                                                        </button>
														{/* currently not working as i will need to find a way to get the actual sample id as it is different to the bs_id */}
                                                        {/* {formData.batch_submission !== 0 && (
                                                            <button type="button" onClick={() => (window.location.href = `/labiq/sample-tracking/${sample.bs_sample_id}`)} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                                                                View Sample
                                                            </button>
                                                        )} */}
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="p-2">
                                                <button type="button" onClick={handleAddSample} className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">
                                                    Add Sample
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Tracking tab */}
                                {activeTab === "Tracking" && (
                                    <div className="grid grid-cols-1">
                                        <p>This section currently is not implemented</p>
                                    </div>
                                )}

                                {/* Other tab */}
                                {activeTab === "Other" && (
                                    <div className="grid grid-cols-1">
                                        <p>This section currently is not implemented</p>
                                    </div>
                                )}

                                {error && <p className="text-red-500">{error}</p>}
                                <div className="flex justify-end space-x-2">
                                    <button type="button" onClick={onClose} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">
                                        Close
                                    </button>
                                    {batch_id !== 0 ? (
                                        <div className="space-x-2">
                                            {formData.batch_submission === 0 && (
                                                <button
                                                    type="button"
                                                    onClick={submitBatchClick}
                                                    disabled={isSubmitting}
                                                    className={`px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
                                                >
                                                    {isSubmitting ? "Saving..." : "Submit Batch"}
                                                </button>
                                            )}

                                            <button type="submit" disabled={isSubmitting} className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}>
                                                {isSubmitting ? "Saving..." : "Save Changes"}
                                            </button>
                                        </div>
                                    ) : (
                                        <button type="submit" disabled={isSubmitting} className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}>
                                            {isSubmitting ? "Saving..." : "Create Batch"}
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default BatchModal;
function foreach() {
	throw new Error("Function not implemented.");
}

