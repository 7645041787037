// src/components/icons/ApplicationIcon.tsx
import React from "react";

const ApplicationIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 3h6v6H3V3zm0 12h6v6H3v-6zm12-12h6v6h-6V3zm0 12h6v6h-6v-6z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 5h6m-3 8v4m0-4h-3m3 0h3m-3-2V9"
    />
  </svg>
);

export default ApplicationIcon;
