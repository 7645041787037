// src/api/companyApi.ts

import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_GENECODES_API;

export interface Panel {
	id_panels: number,
	name_panel: string,
	desc_long: string,
	desc_short: string,
	red_tag: string,
	green_tag: string,
	modality: string,
	panel_sex: string,
}

export const getPanels = async (): Promise<Panel[]> => {
	const response = await axios.get(
        `${API_BASE_URL}/panels`,
        {headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		}}
    );

    return response.data;
}

export const getSamplesPanels = async (authUserId: number): Promise<number[]> => {
	try {
        const response = await axios.post(`${API_BASE_URL}/samplePanels`, {
			"authSampleId": authUserId
		}, {
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		});
        return response.data;
    } catch (error) {
        throw new Error(`Error creating application: ${error}`);
    }
}

export const createOrUpdateSamplesPanels = async (authUserId: number, panelIds: number[]) => {
	try {
        const response = await axios.post(`${API_BASE_URL}/createOrUpdatePanels`, {
			"authSampleId": authUserId,
			"panelIds": panelIds.length > 0 ? panelIds : [],
		}, {
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		});
        return response.data;
    } catch (error) {
        throw new Error(`Error creating application: ${error}`);
    }
}
