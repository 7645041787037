import React, { ChangeEvent, useState, useEffect } from "react";
import { fetchReagentList, Sample } from "../../../../api/labiqApi";
import { fetchCompanies, Company } from "../../../../api/companyApi";

interface SampleDetailsProps {
    formData: Partial<Sample>;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    setError: React.Dispatch<React.SetStateAction<string>>;
    setFormData: React.Dispatch<React.SetStateAction<Partial<Sample>>>;
}

const SampleDetails: React.FC<SampleDetailsProps> = ({ formData, handleChange, handleSelectChange, setError, setFormData }) => {
    const [companies, setCompanies] = useState<Company[]>([]);
    const [reagentList, setReagentList] = useState<string[]>([]);

    //Init on the first render
    useEffect(() => {
        const fetchReagents = async () => {
            try {
                const reagents = await fetchReagentList();
                setReagentList(reagents);
            } catch (err) {
                console.error("Error fetching reagents:", err);
                setError("Failed to load reagents.");
            }
        };
        const getCompanies = async () => {
            try {
                const data = await fetchCompanies();
                setCompanies(data);
            } catch (error) {
                console.error("Error fetching companies:", error);
            }
        };
        getCompanies();
        fetchReagents();
    }, []);

    return (
        <div className="grid grid-cols-2">
            {/* col 1 */}
            <div className="p-6">
                <div className="p-2">
                    <label htmlFor="client_sampleID" className="block text-sm">
                        Sample ID
                    </label>
                    <input type="text" id="client_sampleID" name="client_sampleID" value={formData.client_sampleID || ""} onChange={handleChange} placeholder="Enter sample ID" className="w-full p-2 border rounded" required />
                </div>
                <div className="p-2">
                    <label htmlFor="client" className="block text-sm">
                        Client
                    </label>
                    <select id="client" name="client" value={formData.client || ""} onChange={handleSelectChange} className="w-full p-2 border rounded" required>
                        <option value="" disabled>
                            Select Client
                        </option>
                        {companies.map((company) => (
                            <option key={company.name} value={company.name}>
                                {company.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="p-2">
                    <label htmlFor="analysis_requested" className="block text-sm">
                        Analysis Requested
                    </label>
                    {reagentList.map((reagent) => (
                        <label key={reagent} className="flex items-center mr-4 mb-2">
                            <input
                                type="checkbox"
                                name="analysis_requested"
                                value={reagent}
                                checked={formData.analysis_requested?.includes(reagent) || false}
                                onChange={(e) => {
                                    const { checked, value } = e.target;
                                    setFormData((prev) => {
                                        const analysisRequested = prev.analysis_requested ? [...prev.analysis_requested] : [];
                                        if (checked) {
                                            analysisRequested.push(value);
                                        } else {
                                            const idx = analysisRequested.indexOf(value);
                                            if (idx > -1) {
                                                analysisRequested.splice(idx, 1);
                                            }
                                        }
                                        return { ...prev, analysis_requested: analysisRequested };
                                    });
                                }}
                                className="mr-2"
                            />
                            {reagent}
                        </label>
                    ))}
                </div>
            </div>

            <div className="p-6">
                <div className="p-2">
                    <label htmlFor="job_sequence" className="block text-sm">
                        Sequence
                    </label>
                    <input type="text" id="job_sequence" name="job_sequence" value={formData.job_sequence || ""} onChange={handleChange} placeholder="Enter sequence" className="w-full p-2 border rounded" />
                </div>
                <div className="p-2">
                    <label htmlFor="sampleType" className="block text-sm">
                        Collection Type
                    </label>
                    <select id="sampleType" name="sampleType" value={formData.sampleType || ""} onChange={handleSelectChange} className="w-full p-2 border rounded">
                        <option value="" disabled>
                            Select Collection Type
                        </option>
                        <option value="copan swab">Copan Swab</option>
                        <option value="buccal swab">Buccal Swab</option>
                        <option value="DNAtape">DNAtape</option>
                        <option value="OraGene saliva">OraGene saliva</option>
                    </select>
                </div>
                <div className="p-2">
                    <label htmlFor="status" className="block text-sm">
                        Status
                    </label>
                    <select id="status" name="status" value={formData.status || ""} onChange={handleSelectChange} className="w-full p-2 border rounded">
                        <option value="" disabled>
                            Select Collection Type
                        </option>
                        <option value="submitted">Submitted</option>
                        <option value="received">Received</option>
                        <option value="allocated">Allocated</option>
                        <option value="hold">Hold</option>
                        <option value="pending">Pending</option>
                        <option value="rerun">Rerun</option>
                        <option value="recollect">Recollect</option>
                        <option value="complete">Complete</option>
                        <option value="destroy">Destroy</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default SampleDetails;
