// src/components/Sidebar.tsx
import React from "react";
import { NavLink } from "react-router-dom";
import UsersIcon from "./icons/UsersIcon";
import ApplicationsIcon from "./icons/ApplicationsIcon";
import SubscriptionsIcon from "./icons/SubscriptionsIcon";
import PlansIcon from "./icons/PlansIcon";
import SettingsIcon from "./icons/SettingsIcon";
import DashboardIcon from "./icons/DashboardIcon";
import LogoutIcon from "./icons/LogoutIcon";

const Sidebar: React.FC = () => {
    return (
        <div className="bg-gray-800 text-white w-64 min-h-screen flex flex-col justify-between">
            <div className="mt-4">
                <NavItem to="/" icon={<DashboardIcon className="w-6 h-6" />} label="Dashboard" />

                {/* Authentication server systems  */}
                {JSON.parse(sessionStorage.getItem("user") || "{}").roles?.some((role: any) => role.name === "User Management") && (
                    <div className="mt-4">
                        <h2 className="text-gray-400 ml-4 text-xs uppercase font-bold tracking-wider">Admin Management</h2>

                        <NavItem to="/users" icon={<UsersIcon className="w-6 h-6" />} label="User Management" />
                        <NavItem to="/roles" icon={<PlansIcon className="w-6 h-6" />} label="Role Management" />
                        <NavItem to="/applications" icon={<ApplicationsIcon className="w-6 h-6" />} label="Application Management" />
                        <NavItem to="/subscriptions" icon={<SubscriptionsIcon className="w-6 h-6" />} label="Subscription Management" />
                        <NavItem to="/plans" icon={<PlansIcon className="w-6 h-6" />} label="Plan Management" />
                    </div>
                )}

				{/* Authentication server systems  */}
                {JSON.parse(sessionStorage.getItem("user") || "{}").roles?.some((role: any) => role.name === "Practitioner") && (
                    <div className="mt-4">
                        <h2 className="text-gray-400 ml-4 text-xs uppercase font-bold tracking-wider">Healthcare</h2>
                        <NavItem to="/healthcare/patients" icon={<UsersIcon className="w-6 h-6" />} label="Patients" />
                    </div>
                )}


                {/* Lab IQ management system */}
                {JSON.parse(sessionStorage.getItem("user") || "{}").roles?.some((role: any) => role.name === "Lab Management" || role.name === "Practitioner") && (
                    <div className="mt-4">
                        <h2 className="text-gray-400 ml-4 text-xs uppercase font-bold tracking-wider">LabIQ</h2>
                        <NavItem to="/labiq/sample-tracking" icon={<UsersIcon className="w-6 h-6" />} label="Sample Tracking" />
						{JSON.parse(sessionStorage.getItem("user") || "{}").roles?.some((role: any) => role.name === "Lab Management") && (

                        <NavItem to="/labiq/batch-management" icon={<SubscriptionsIcon className="w-6 h-6" />} label="Batch Management" />
						)}
						</div>
                )}

                {/* Inventory management system */}
                {JSON.parse(sessionStorage.getItem("user") || "{}").roles?.some((role: any) => role.name === "Inventory Management") && (
                    <div className="mt-4">
                        <h2 className="text-gray-400 ml-4 text-xs uppercase font-bold tracking-wider">Inventory</h2>
                        <NavItem to="/inventory/products" icon={<UsersIcon className="w-6 h-6" />} label="Products" />
						<NavItem to="/inventory/ordering" icon={<UsersIcon className="w-6 h-6" />} label="Ordering" />
						<NavItem to="/inventory/suppliers" icon={<UsersIcon className="w-6 h-6" />} label="Suppliers" />
                    </div>
                )}

                {/*  */}
            </div>
            <div className="mb-4">
                {/* <NavItem to="/settings" icon={<SettingsIcon className="w-6 h-6" />} label="Settings" /> */}
                <NavItem to="/logout" icon={<LogoutIcon className="w-6 h-6" />} label="Logout" />
            </div>
        </div>
    );
};

interface NavItemProps {
    to: string;
    icon: React.ReactNode;
    label: string;
}

const NavItem: React.FC<NavItemProps> = ({ to, icon, label }) => {
    return (
        <NavLink to={to} className={({ isActive }) => `flex items-center px-4 py-2 transition-colors duration-200 ${isActive ? "bg-gray-700" : "hover:bg-gray-700"}`}>
            {icon}
            <span className="ml-3">{label}</span>
        </NavLink>
    );
};

export default Sidebar;
