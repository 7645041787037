

import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_AUTH_SERVER;

export interface Role {
    id: number;
    name: string;
    guard_name: string;
    created_at: string;
    updated_at: string;
    pivot: {
        model_type: string;
        model_id: number;
        role_id: number;
    };
}

export const getAllRoles = async () => {
    const response = await axios.get(`${API_BASE_URL}/admin/roles/`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return response.data;
};

export const getUsersByRole = async (roleId: number) => {
	const response = await axios.get(`${API_BASE_URL}/admin/roles/${roleId}/users`, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};

export const createRole = async (name: string) => {
	const response = await axios.post(`${API_BASE_URL}/admin/roles/`, {
		name,
	}, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
}

export const deleteRole = async (role: string) => {
	const response = await axios.delete(`${API_BASE_URL}/admin/roles/${role}`, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
}



// Assigning roles to applicaitons
export const assignApplicationsToRole = async (roleId: number, application_ids: number[]) => {
	const response = await axios.post(`${API_BASE_URL}/admin/roles/${roleId}/applications`, {
		application_ids,
	}, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};

export const removeApplicationFromRole = async (roleId: number, applicationId: number) => {
	const response = await axios.delete(`${API_BASE_URL}/admin/roles/${roleId}/applications/${applicationId}`, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};

export const getApplicationsByRole = async (roleId: number) => {
	const response = await axios.get(`${API_BASE_URL}/admin/roles/${roleId}/applications`, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};

export const getRolesByApplication = async (applicationId: number) => {
	const response = await axios.get(`${API_BASE_URL}/admin/applications/${applicationId}/roles`, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};