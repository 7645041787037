import React from 'react';
import { Patient } from '../../../api/patientApi';

interface PatientTableProps {
	patients: Patient[];
	onEditClick: (patient: Patient) => void;
}

const PatientTable: React.FC<PatientTableProps> = ({ patients, onEditClick }) => {
	// Add a check to handle empty or undefined patients
	if (!patients || patients.length === 0) {
		return <p>No patients available.</p>;
	}

	return (
		<div className="overflow-x-auto">
			<table className="min-w-full bg-white">
				<thead>
					<tr>
						<th className="py-2 px-4 border-b">ID</th>
						
						<th className="py-2 px-4 border-b">First Name</th>
						<th className="py-2 px-4 border-b">Last Name</th>
						<th className='py-2 px-4 border-b'>Phone</th>
						<th className="py-2 px-4 border-b">Email</th>
						<th className="py-2 px-4 border-b">Is Active</th>
						<th className="py-2 px-4 border-b">Actions</th>
					</tr>
				</thead>
				<tbody>
					{patients.map((patient) => (
						<tr key={patient.id} className="text-center">
							<td className="py-2 px-4 border-b">{patient.id}</td>
							
							<td className="py-2 px-4 border-b">{patient.first_name}</td>
							<td className="py-2 px-4 border-b">{patient.last_name}</td>
							<td className="py-2 px-4 border-b">{patient.phone}</td>
							<td className="py-2 px-4 border-b">{patient.email}</td>
							{patient.is_active ? <td className="py-2 px-4 border-b" style={{backgroundColor : 'green'}}>True</td> : <td className="py-2 px-4 border-b" style={{backgroundColor : 'red'}}>False</td>}
							
							<td className="py-2 px-4 border-b">
								<button
									onClick={() => onEditClick(patient)}
									className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
								>
									Edit
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default PatientTable;
