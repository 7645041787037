// src/pages/UserManagementPage.tsx
import React from 'react';
import Layout from '../components/Layout';

const SettingsPage: React.FC = () => {
  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">User Management</h1>
      {/* User management content goes here */}
    </Layout>
  );
};

export default SettingsPage;
