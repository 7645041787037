// src/components/Navbar.tsx
import React from "react";
import { Link } from "react-router-dom";

const Navbar: React.FC = () => {
    const user = JSON.parse(sessionStorage.getItem("user") || "{}");

    return (
        <nav className="bg-white border-b border-gray-200 px-4 py-2 flex justify-between items-center">
            <div className="text-xl font-semibold">Admin Platform</div>
            <div className="flex items-center">
                <span className="mr-3 text-gray-700 hidden sm:block">
                    {user.first_name} {user.last_name}
                </span>
                <Link to="/profile">
                    <img className="w-8 h-8 rounded-full object-cover" src={user.profile_picture || "/default-profile.png"} alt={`${user.first_name} ${user.last_name}`} />
                </Link>
            </div>
        </nav>
    );
};

export default Navbar;
