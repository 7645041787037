import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import RoleTable from "../../components/roles/RoleTable";
import { getAllRoles, Role } from "../../api/rolesApi";
import EditRoleModal from "../../components/roles/EditRoleModal";
import RoleButtons from "../../components/roles/RoleButtons";

const RoleManagementPage: React.FC = () => {
	const [roles, setRoles] = useState<Role[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedRole, setSelectedRole] = useState<Role | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
        const loadRoles = async () => {
            setIsLoading(true);
            try {
                const rolesData = await getAllRoles();
                setRoles(rolesData);
                setError(null);
            } catch (err) {
                console.error("Error fetching roles:", err);
                setError("Failed to load roles.");
            } finally {
                setIsLoading(false);
            }
        };

        loadRoles();
    }, []);

	const handleEditClick = (role: Role) => {
		setSelectedRole(role);
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setSelectedRole(null);
		setIsModalOpen(false);
	};

	const handleRoleUpdate = async () => {
		try {
			setIsLoading(true);
			const { data } = await getAllRoles();
			setRoles(data);
			setError(null);
		} catch (err) {
			console.error("Error fetching updated roles:", err);
			setError("Failed to load updated roles.");
		} finally {
			setIsLoading(false);
			handleModalClose();
		}
	};

	return (
		<Layout>
			<h1 className="text-2xl font-bold mb-4">Role Management</h1>
			{isLoading ? (
				<p>Loading roles...</p>
			) : (
				<>
					<RoleButtons />
					<RoleTable roles={roles} onEditClick={handleEditClick} />
				</>
			)}
			{selectedRole && (
				<EditRoleModal
					isOpen={isModalOpen}
					onClose={handleModalClose}
					role={selectedRole}
					onRoleUpdated={handleRoleUpdate}
				/>
			)}
		</Layout>
	);
};

export default RoleManagementPage;
