import React, { useState } from 'react';
import { Patient } from "../../../api/patientApi";
import EditPatientModal from "../../healthcare/patient/EditPatientModal";

const createEmptyPatient = (): Patient => ({
	id: 0,
	uuid: "",
	username: "",
	email: "",
	password: "",
	password_confirmation: "",
	first_name: "",
	last_name: "",
	phone: "",
	address: "",
	city: "",
	state: "",
	country: "",
	postal_code: "",
	profile_picture: "",
	date_of_birth: new Date(),
	gender: "",
	company: "",
	panel_key: "",
	company_code: "",
	reagents: "",
	preferences: "",
	is_active: false,
	created_at: new Date(),
	updated_at: new Date(),
});

interface PatientButtonsProps {
	searchPatients: (search: string) => void;
	reloadPatients: () => void;
}

const PatientButtons: React.FC<PatientButtonsProps> = ({searchPatients, reloadPatients}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [newPatient, setSelectedPatient] = useState<Patient>(createEmptyPatient());
	const [searchTerm, setSearchTerm] = useState("");

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        searchPatients(searchTerm);
    };

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	const handleCreatePatient = () => {
		handleModalClose();
	};

	return (
        <div>
            <div className="mb-4 flex justify-between">
                <button onClick={handleOpenModal} className="px-4 py-2 bg-gray-200 rounded opacity-100 hover:bg-gray-300">
                    Create Patient
                </button>
                <div className="justify-end">
                    <input type="text" placeholder="Search patients..." value={searchTerm} onChange={handleSearchChange} className="border px-4 py-2 rounded" />
                    <button onClick={reloadPatients} className="px-4 py-2 bg-gray-200 rounded opacity-100 hover:bg-gray-300">
                        Reset
                    </button>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && <EditPatientModal isOpen={isModalOpen} onClose={handleModalClose} patient={newPatient} onPatientUpdated={handleCreatePatient} />}
        </div>
    );
};

export default PatientButtons;