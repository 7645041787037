// src/components/orders/SalesOrderModal.tsx

import React, { useState, useEffect } from "react";
import { SalesOrder, SalesOrderItem } from "../../../api/salesOrderApi";
import { fetchCompanies, Company } from "../../../api/companyApi";
import { fetchUsers, User } from "../../../api/userApi";
import { fetchProducts, Product } from "../../../api/productApi";

interface SalesOrderModalProps {
    isOpen: boolean;
    onClose: () => void;
    order: SalesOrder | null;
    onSave: (orderData: SalesOrder) => void;
}

const SalesOrderModal: React.FC<SalesOrderModalProps> = ({ isOpen, onClose, order, onSave }) => {
    const [formData, setFormData] = useState<SalesOrder>(order || { assignee_type: "user", assignee_id: "", items: [], status: "pending" });
    const [assigneeType, setAssigneeType] = useState<string>("user");
    const [companies, setCompanies] = useState<Company[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [newItem, setNewItem] = useState<Partial<SalesOrderItem>>({});
    const [searchTerm, setSearchTerm] = useState<string>("");

    useEffect(() => {
        if (isOpen) {
            setFormData(order || { assignee_type: "user", assignee_id: "", items: [], status: "pending" });
            setAssigneeType(order?.assignee_type || "user");
            loadProducts();
            if (assigneeType === "user") {
                loadUsers();
            } else {
                loadCompanies();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, assigneeType]);

    const loadUsers = async () => {
        try {
            const {data, total} = await fetchUsers(1, 20);
            setUsers(data);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const loadCompanies = async () => {
        try {
            const data = await fetchCompanies();
            setCompanies(data);
        } catch (error) {
            console.error("Error fetching companies:", error);
        }
    };

    const loadProducts = async () => {
        try {
			let company = JSON.parse(localStorage.getItem('user') || '{}').company;
            const {data, total} = await fetchProducts(1, 20, company);
            setProducts(data);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    const handleAssigneeTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setAssigneeType(value);
        setFormData((prevData) => ({ ...prevData, assignee_type: value, assignee_id: "" }));
        setSearchTerm("");
        if (value === "user") {
            loadUsers();
        } else {
            loadCompanies();
        }
    };

    const handleAssigneeIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setFormData((prevData) => ({ ...prevData, assignee_id: value }));
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const filteredAssignees = () => {
        if (assigneeType === "user") {
            return users.filter((user) => user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) || user.email.toLowerCase().includes(searchTerm.toLowerCase()));
        } else {
            return companies.filter((company) => company.name.toLowerCase().includes(searchTerm.toLowerCase()));
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleAddItem = () => {
        if (newItem.product_id && newItem.quantity && newItem.unit_price) {
            setFormData((prevData) => ({
                ...prevData,
                items: [...(prevData.items || []), newItem as SalesOrderItem],
            }));
            setNewItem({});
        } else {
            alert("Please fill in all item fields.");
        }
    };

    const handleItemChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            const items = prevData.items || [];
            items[index] = { ...items[index], [name]: value };
            return { ...prevData, items };
        });
    };

    const handleDeleteItem = (index: number) => {
        setFormData((prevData) => {
            const items = prevData.items || [];
            items.splice(index, 1);
            return { ...prevData, items };
        });
    };

    const calculateTotalAmount = () => {
        const items = formData.items || [];
        const total = items.reduce((sum, item) => sum + item.quantity * item.unit_price, 0);
        return total.toFixed(2);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const totalAmount = parseFloat(calculateTotalAmount());
        onSave({ ...formData, total_amount: totalAmount });
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-auto">
            <div className="bg-white p-6 rounded shadow-md w-full max-w-3xl">
                <h2 className="text-xl font-bold mb-4">{order ? "Edit Sales Order" : "Add Sales Order"}</h2>
                <form onSubmit={handleSubmit}>
                    {/* Assignee Type */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Assignee Type</label>
                        <select name="assignee_type" value={assigneeType} onChange={handleAssigneeTypeChange} required className="w-full border px-3 py-2 rounded">
                            <option value="user">User (B2C)</option>
                            <option value="company">Company (B2B)</option>
                        </select>
                    </div>
                    {/* Assignee Selection */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Assignee</label>
                        <input type="text" placeholder="Search..." value={searchTerm} onChange={handleSearchChange} className="w-full border px-3 py-2 rounded mb-2" />
                        <select name="assignee_id" value={formData.assignee_id} onChange={handleAssigneeIdChange} required className="w-full border px-3 py-2 rounded" size={5}>
                            {filteredAssignees().map((assignee) =>
                                assigneeType === "user" ? (
                                    <option key={assignee.id} value={assignee.id}>
                                        {(assignee as User).first_name} - {assignee.email}
                                    </option>
                                ) : (
                                    <option key={assignee.id} value={assignee.id}>
                                        {(assignee as Company).name}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                    {/* Order Date */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Order Date</label>
                        <input type="date" name="order_date" value={formData.order_date || ""} onChange={handleChange} className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* Status */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Status</label>
                        <select name="status" value={formData.status || "pending"} onChange={handleChange} className="w-full border px-3 py-2 rounded">
                            <option value="pending">Pending</option>
                            <option value="completed">Completed</option>
                            {/* Add more statuses as needed */}
                        </select>
                    </div>
                    {/* Notes */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Notes</label>
                        <textarea name="notes" value={formData.notes || ""} onChange={handleChange} className="w-full border px-3 py-2 rounded"></textarea>
                    </div>
                    {/* Items */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Items</h3>
                        {/* Existing Items */}
                        {formData.items && formData.items.length > 0 && (
                            <table className="min-w-full bg-white border mb-4">
                                <thead>
                                    <tr>
                                        <th className="py-2 px-3 border-b">Product</th>
                                        <th className="py-2 px-3 border-b">Quantity</th>
                                        <th className="py-2 px-3 border-b">Unit Price</th>
                                        <th className="py-2 px-3 border-b">Notes</th>
                                        <th className="py-2 px-3 border-b">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.items.map((item, index) => (
                                        <tr key={index}>
                                            <td className="py-2 px-3 border-b">
                                                <select name="product_id" value={item.product_id} onChange={(e) => handleItemChange(e, index)} required className="w-full border px-2 py-1 rounded">
                                                    <option value="">Select a product</option>
                                                    {products.map((product) => (
                                                        <option key={product.product_id} value={product.product_id}>
                                                            {product.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td className="py-2 px-3 border-b">
                                                <input type="number" name="quantity" value={item.quantity} onChange={(e) => handleItemChange(e, index)} required className="w-full border px-2 py-1 rounded" />
                                            </td>
                                            <td className="py-2 px-3 border-b">
                                                <input type="number" name="unit_price" step="0.01" value={item.unit_price} onChange={(e) => handleItemChange(e, index)} required className="w-full border px-2 py-1 rounded" />
                                            </td>
                                            <td className="py-2 px-3 border-b">
                                                <input type="text" name="notes" value={item.notes || ""} onChange={(e) => handleItemChange(e, index)} className="w-full border px-2 py-1 rounded" />
                                            </td>
                                            <td className="py-2 px-3 border-b">
                                                <button type="button" onClick={() => handleDeleteItem(index)} className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600">
                                                    Remove
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {/* Add New Item */}
                        <div className="border p-4 rounded mb-4">
                            <h4 className="font-medium mb-2">Add Item</h4>
                            <div className="grid grid-cols-4 gap-4">
                                {/* Product */}
                                <select name="product_id" value={newItem.product_id || ""} onChange={(e) => setNewItem((prev) => ({ ...prev, product_id: parseInt(e.target.value, 10) }))} required className="border px-2 py-1 rounded">
                                    <option value="">Product</option>
                                    {products.map((product) => (
                                        <option key={product.product_id} value={product.product_id}>
                                            {product.name}
                                        </option>
                                    ))}
                                </select>
                                {/* Quantity */}
                                <input
                                    type="number"
                                    name="quantity"
                                    value={newItem.quantity || ""}
                                    onChange={(e) => setNewItem((prev) => ({ ...prev, quantity: parseInt(e.target.value, 10) }))}
                                    placeholder="Quantity"
                                    required
                                    className="border px-2 py-1 rounded"
                                />
                                {/* Unit Price */}
                                <input
                                    type="number"
                                    name="unit_price"
                                    step="0.01"
                                    value={newItem.unit_price || ""}
                                    onChange={(e) => setNewItem((prev) => ({ ...prev, unit_price: parseFloat(e.target.value) }))}
                                    placeholder="Unit Price"
                                    required
                                    className="border px-2 py-1 rounded"
                                />
                                {/* Notes */}
                                <input type="text" name="notes" value={newItem.notes || ""} onChange={(e) => setNewItem((prev) => ({ ...prev, notes: e.target.value }))} placeholder="Notes" className="border px-2 py-1 rounded" />
                            </div>
                            <button type="button" onClick={handleAddItem} className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
                                Add Item
                            </button>
                        </div>
                    </div>
                    {/* Total Amount */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Total Amount</label>
                        <input type="text" value={`$${calculateTotalAmount()}`} readOnly className="w-full border px-3 py-2 rounded bg-gray-100" />
                    </div>
                    {/* Buttons */}
                    <div className="flex justify-end">
                        <button type="button" onClick={onClose} className="mr-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">
                            Cancel
                        </button>
                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                            {order ? "Update" : "Create"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SalesOrderModal;
