import React, { ChangeEvent } from "react";
import { Sample } from "../../../../api/labiqApi";

interface SampleOtherProps {
    formData: Partial<Sample>;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const SampleOther: React.FC<SampleOtherProps> = ({ formData, handleChange, handleTextAreaChange }) => {
    return (
        <div className="grid grid-cols-1">
            <div className="p-2">
                <label htmlFor="comments" className="block text-sm">
                    Comments
                </label>
                <textarea id="comments" name="comments" value={formData.comments || ""} onChange={handleTextAreaChange} placeholder="Enter comments" className="w-full p-2 border rounded h-32" />
            </div>
            <div className="p-2">
                <label htmlFor="lab_location" className="block text-sm">
                    Lab Location
                </label>
                <input type="text" id="lab_location" name="lab_location" value={formData.lab_location || ""} onChange={handleChange} placeholder="Enter lab location" className="w-full p-2 border rounded" />
            </div>
            <div className="p-2">
                <label htmlFor="available_material" className="block text-sm">
                    Available Material
                </label>
                <input type="text" id="available_material" name="available_material" value={formData.available_material || ""} onChange={handleChange} placeholder="Enter available material" className="w-full p-2 border rounded" />
            </div>
        </div>
    );
};

export default SampleOther;
