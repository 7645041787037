// src/components/suppliers/SupplierModal.tsx

import React, { useState, useEffect } from "react";
import { Supplier } from "../../../api/supplierApi";

interface SupplierModalProps {
    isOpen: boolean;
    onClose: () => void;
    supplier: Supplier | null;
    onSave: (supplierData: Supplier) => void;
}

const SupplierModal: React.FC<SupplierModalProps> = ({ isOpen, onClose, supplier, onSave }) => {
    const [formData, setFormData] = useState<Supplier>(supplier || { name: "" });

    useEffect(() => {
        if (isOpen) {
            setFormData(supplier || { name: "" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!formData.name) {
            alert("Supplier name is required.");
            return;
        }
        onSave(formData);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-auto">
            <div className="bg-white p-6 rounded shadow-md w-full max-w-lg">
                <h2 className="text-xl font-bold mb-4">{supplier ? "Edit Supplier" : "Add Supplier"}</h2>
                <form onSubmit={handleSubmit}>
                    {/* Name */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Name</label>
                        <input name="name" type="text" value={formData.name || ""} onChange={handleChange} required className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* Contact Name */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Contact Name</label>
                        <input name="contact_name" type="text" value={formData.contact_name || ""} onChange={handleChange} className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* Email */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Email</label>
                        <input name="email" type="email" value={formData.email || ""} onChange={handleChange} className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* Phone */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Phone</label>
                        <input name="phone" type="text" value={formData.phone || ""} onChange={handleChange} className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* Address */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Address</label>
                        <textarea name="address" value={formData.address || ""} onChange={handleChange} className="w-full border px-3 py-2 rounded"></textarea>
                    </div>
                    {/* Buttons */}
                    <div className="flex justify-end">
                        <button type="button" onClick={onClose} className="mr-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">
                            Cancel
                        </button>
                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                            {supplier ? "Update" : "Create"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SupplierModal;
