// src/pages/inventory/StockAssignmentPage.tsx

import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Layout from "../../components/Layout";
import StockAssignmentTable from "../../components/inventory/stockAssignment/StockAssignmentTable";
import Pagination from "../../components/Pagination";
import StockAssignmentModal from "../../components/inventory/stockAssignment/StockAssignmentModal";
import { StockAssignment, fetchStockAssignments, createStockAssignment, updateStockAssignment, deleteStockAssignment } from "../../api/stockAssignmentApi";
import { fetchStockEntry, Stock } from "../../api/stockApi";
import { fetchSingleUserByUuid } from "../../api/userApi";
import { fetchSingleCompany } from "../../api/companyApi";

const StockAssignmentPage: React.FC = () => {
    const { stockId } = useParams<{ stockId: string }>();
    const [assignments, setAssignments] = useState<StockAssignment[]>([]);
    const [totalAssignments, setTotalAssignments] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedAssignment, setSelectedAssignment] = useState<StockAssignment | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [stock, setStock] = useState<Stock | null>(null);

    const ASSIGNMENTS_PER_PAGE = 20;
    const stockIdNumber = stockId ? parseInt(stockId, 10) : 0;

    useEffect(() => {
        loadStock();
        loadAssignments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const loadStock = async () => {
        try {
            const stockData = await fetchStockEntry(stockIdNumber);
            setStock(stockData);
        } catch (error) {
            console.error("Error fetching stock:", error);
        }
    };

    const loadAssignments = async () => {
        setIsLoading(true);
        try {
            const { data, total } = await fetchStockAssignments(currentPage, ASSIGNMENTS_PER_PAGE, {
                stock_id: stockIdNumber,
            });
            if (data) {
                for (const item of data) {
                    if (item.assignee_type === "user") {
                        try {
                            const user = await fetchSingleUserByUuid(item.assignee_id);
                            item.assignee_id = user.data.first_name + " " + user.data.last_name;
                        } catch (error) {
                            console.error("Error fetching user:", error);
                        }
                    }
					if (item.assignee_type === "company") {
                        try {
                            const company = await fetchSingleCompany(item.assignee_id);
                            item.assignee_id = company.name;
                        } catch (error) {
                            console.error("Error fetching user:", error);
                        }
                    }
                }
            }

            setAssignments(data);
            setTotalAssignments(total);
        } catch (error) {
            console.error("Error fetching stock assignments:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditClick = (assignment: StockAssignment) => {
        setSelectedAssignment(assignment);
        setIsModalOpen(true);
    };

    const handleDeleteClick = async (assignment: StockAssignment) => {
        if (window.confirm(`Are you sure you want to delete the assignment with ID "${assignment.assignment_id}"?`)) {
            try {
                await deleteStockAssignment(assignment.assignment_id);
                loadAssignments();
            } catch (error) {
                console.error("Error deleting assignment:", error);
            }
        }
    };

    const handleModalClose = () => {
        setSelectedAssignment(null);
        setIsModalOpen(false);
    };

    const handleAssignmentSave = async (assignmentData: StockAssignment) => {
        try {
            if (selectedAssignment) {
                // Update assignment
                await updateStockAssignment(selectedAssignment.assignment_id, assignmentData);
            } else {
                let companyName = "";
                if (assignmentData.assignee_type === "company") {
                    const company = await fetchSingleCompany(assignmentData.assignee_id);
                    companyName = company.name;
                }
                await createStockAssignment(assignmentData, companyName);
            }
            loadAssignments();
            handleModalClose();
        } catch (error) {
            console.error("Error saving assignment:", error);
        }
    };

    const handleAddAssignment = () => {
        setSelectedAssignment(null);
        setIsModalOpen(true);
    };

    return (
        <Layout>
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Stock Assignments {stock ? `for Stock ID ${stock.stock_id}` : ""}</h1>
                <Link to={`/inventory/products/${stock?.product_id}/stock`} className="text-blue-500 hover:underline">
                    Back to Stock Entries
                </Link>
            </div>
            <div className="mb-4">
                <button onClick={handleAddAssignment} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                    Add Assignment
                </button>
            </div>
            {isLoading ? (
                <p>Loading assignments...</p>
            ) : (
                <>
                    <StockAssignmentTable assignments={assignments} onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} />
                    <Pagination currentPage={currentPage} totalItems={totalAssignments} itemsPerPage={ASSIGNMENTS_PER_PAGE} onPageChange={setCurrentPage} />
                </>
            )}
            {isModalOpen && <StockAssignmentModal isOpen={isModalOpen} onClose={handleModalClose} assignment={selectedAssignment} onSave={handleAssignmentSave} stockId={stockIdNumber} />}
        </Layout>
    );
};

export default StockAssignmentPage;
