import React, { Fragment, useState, useEffect } from "react";
import { Dialog, DialogTitle, Transition } from "@headlessui/react";
import { activatePatient, deactivatePatient, updatePatient, Patient, createPatient, MygeneProgramStatus, fetchPatientProgramStatus, updatePatientProgramStatus, createPatientProgramStatus } from "../../../api/patientApi";
import NavButtons from "./modalComponents/NavButtons";
import ProfileTab from "./modalComponents/ProfileTab";
import SampleTab from "./modalComponents/SampleTab";
import MygeneProgramStatusTab from "./modalComponents/MygeneProgramStatusTab";

interface EditPatientModalProps {
    isOpen: boolean;
    onClose: () => void;
    patient: Patient;
    onPatientUpdated: () => void;
}

const EditPatientModal: React.FC<EditPatientModalProps> = ({ isOpen, onClose, patient, onPatientUpdated }) => {
    const [updatedPatient, setUpdatedPatient] = useState<Patient>({ ...patient });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [activeTab, setActiveTab] = useState("Personal");
	const [status, setStatus] = useState<MygeneProgramStatus>();

    useEffect(() => {
        setUpdatedPatient({ ...patient });
		if (patient.company === "") {
			const userCompany = JSON.parse(sessionStorage.getItem("user") || "{}").company;
			setUpdatedPatient((prevPatient) => ({
				...prevPatient,
				company: userCompany,
				company_code: userCompany,
			}));
		}

		if (patient.id !== 0) {
            const fetchStatus = async () => {
                const response = await fetchPatientProgramStatus(patient.id);
                setStatus(response.data);
            };
            fetchStatus();
		}else{
			setStatus({
				user_id: 0,
				program_elected: "NA",
				swab_kit_sent: false,
				swab_kit_returned: false,
				questionnaire_sent: false,
				questionnaire_completed: false,
				questionnaire_sent_admin: false,
				pedigree_chart_filled: false,
				pathology_consult_completed: false,
				pathology_forms_sent: false,
				initial_blood_test_completed: false,
				genetic_results_ready: false,
				consult_90_minute_booked: false,
				consult_90_minute_completed: false,
				date_90_minute_consult: null,
				initial_blood_consult_completed: false,
				date_initial_blood_consult: null,
				follow_up_blood_test_due: null,
				follow_up_blood_test_completed: false,
				follow_up_blood_consult_completed: false,
			});
		}
    }, [patient]);

    // Activate or deactivate patient account
    const onActiveClick = async () => {
        setIsSubmitting(true);
        try {
            let updatedPatientStatus = { ...updatedPatient };
            if (!updatedPatient.is_active) {
                await activatePatient(patient.id);
                updatedPatientStatus.is_active = true;
            } else {
                await deactivatePatient(patient.id);
                updatedPatientStatus.is_active = false;
            }
            setUpdatedPatient(updatedPatientStatus);
            onPatientUpdated();
        } catch (err) {
            console.error("Error updating patient:", err);
            setError("Failed to update patient.");
        } finally {
            setIsSubmitting(false);
        }
    };

    // Update patient information
    const updatePatientInfo = async () => {
        setIsSubmitting(true);
        try {
            if (!updatedPatient.first_name || !updatedPatient.last_name || !updatedPatient.email || !updatedPatient.username) {
                setError("First name, last name, email, and username are required.");
                setIsSubmitting(false);
                return;
            }

            if (patient.id !== 0) {
                await updatePatient(patient.id, updatedPatient);
				await updatePatientProgramStatus(patient.id, status as MygeneProgramStatus);
            } else {
                const newPatient = await createPatient(updatedPatient);
                setStatus((prevStatus) => {
                    const updatedStatus = {
                        ...prevStatus!,
                        user_id: newPatient.id,
                    };
                    createPatientProgramStatus(updatedStatus);
                    return updatedStatus;
                });
            }
            onPatientUpdated();
        } catch (err) {
            console.error("Error updating patient:", err);
            setError("Failed to update patient.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
                <div className="flex items-center justify-center min-h-screen px-4">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black opacity-30" />
                    </Transition.Child>

                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                        <div className="bg-white rounded p-6 z-20 w-[80vw] h-[80vh] flex flex-col">
                            {/* Modal heading */}
                            <DialogTitle className="text-xl font-semibold mb-4">
                                Edit {updatedPatient.first_name} {updatedPatient.last_name}
                            </DialogTitle>
                            {/* Modal tabs */}
                            <div className="flexspace-x-4 mb-6 border-b">
                                <NavButtons activeTab={activeTab} setActiveTab={setActiveTab} patient={patient} />
                            </div>

                            <div className="flex flex-col justify-between h-full">
                                <div className="flex-1 overflow-y-auto">
                                    {/* Personal Info Tab */}
                                    {activeTab === "Personal" && <ProfileTab updatedPatient={updatedPatient} setUpdatedPatient={setUpdatedPatient} />}

                                    {/* Samples Tab */}
                                    {activeTab === "Samples" && <SampleTab patient={patient} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} setError={setError} />}

                                    {/* MygeneProgramStatus Tab */}
                                    {activeTab === "MygeneProgramStatus" && status && <MygeneProgramStatusTab status={status} setStatus={setStatus} />}
                                </div>

                                {error && <p className="text-red-500">{error}</p>}
                                <div className="flex justify-end space-x-2 mt-6">
                                    <button type="button" onClick={onActiveClick} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">
                                        {updatedPatient.is_active ? "Deactivate" : "Activate"}
                                    </button>
                                    <button type="button" onClick={onClose} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">
                                        Cancel
                                    </button>
                                    {patient.id !== 0 ? (
                                        <button onClick={updatePatientInfo} disabled={isSubmitting} className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}>
                                            {isSubmitting ? "Saving..." : "Save Changes"}
                                        </button>
                                    ) : (
                                        <button onClick={updatePatientInfo} disabled={isSubmitting} className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}>
                                            {isSubmitting ? "Saving..." : "Create Patient"}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default EditPatientModal;
