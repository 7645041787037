import React, { useState } from "react";
import axios from "axios";

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const authServerBaseUrl = process.env.REACT_APP_AUTH_SERVER;

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
			// Send the login request to the api
            const response = await axios.post(`${authServerBaseUrl}/login`, {
                email,
                password,
            });
            // Extract the token and user from the response and verify the users rtoles
            const { token, user } = response.data;

            const hasAdminRole = user.roles.some((role: any) => role.name === "admin" || role.name === "Practitioner" || role.name === "Lab Management");

            if (!hasAdminRole) {
                setError("Unauthorized: You do not have permissions");
                return;
            }

            // Store the token in sessionStorage if the user is an admin (store as auth so that in the future we can get multiple api keys depending on the roles the user has)
            sessionStorage.setItem("apiKey", token);
            sessionStorage.setItem("user", JSON.stringify(user));

            // Redirect to the dashboard after successful login
            window.location.href = "/";
        } catch (err) {
			if(axios.isAxiosError(err)){
				if(err.response){
					setError(err.response.data.message);
				}
			}else{
				setError("Something has gone wrong, please try again");
			}
			
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
				<img src="/assets/images/logo/logoBlack.png" alt="The mygene logo" />
                <h2 className="text-2xl font-bold mb-6 text-center">Admin Login</h2>
                <form onSubmit={handleLogin} className="space-y-4">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            className="mt-1 w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            className="mt-1 w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>

                    {error && <p className="text-red-500 text-sm">{error}</p>}

                    <button type="submit" className="w-full bg-blue-600 text-white py-3 rounded-md font-semibold hover:bg-blue-700 transition duration-300">
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
