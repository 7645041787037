import React, { Fragment, useState, useEffect } from "react";
import { Dialog, DialogTitle, Transition } from "@headlessui/react";
import { activateUser, deactivateUser, updateUser, assignUserRole, removeUserRole, User, createUser } from "../../api/userApi";
import { getAllRoles, Role } from "../../api/rolesApi";
import { fetchCompanies, Company } from "../../api/companyApi";


interface EditUserModalProps {
    isOpen: boolean;
    onClose: () => void;
    user: User;
    onUserUpdated: (user: User) => void;
}

const EditUserModal: React.FC<EditUserModalProps> = ({ isOpen, onClose, user, onUserUpdated }) => {
    const [formData, setFormData] = useState<Partial<User>>({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        username: user.username,
		password: user.password,
		password_confirmation: user.password_confirmation,
        phone: user.phone,
        address: user.address,
        city: user.city,
        state: user.state,
        country: user.country,
        postal_code: user.postal_code,
        profile_picture: user.profile_picture,
        date_of_birth: user.date_of_birth,
        gender: user.gender,
        preferences: user.preferences,
        is_active: user.is_active,
		company: user.company,
		panel_key: user.panel_key,
		company_code: user.company_code,
		reagents: user.reagents,
        roles: user.roles,
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
	const [activeTab, setActiveTab] = useState("Personal");
	const [roles, setRoles] = useState<Role[]>();
	const [companies, setCompanies] = useState<Company[]>([]);
	const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);

	
	//Init on the first render
	useEffect(() => {
		const fetchRoles = async () => {
			try {
				const roles = await getAllRoles();
				setRoles(roles);
			} catch (error) {
				console.error("Error fetching roles:", error);
			}
		};
		fetchCompaniesList();
		fetchRoles();
	}, []);
	

	const fetchCompaniesList = async () => {
        try {
            const data = await fetchCompanies();
            setCompanies(data || []);
            setFilteredCompanies(data || []);
        } catch (error) {
            console.error("Error fetching companies:", error);
            setCompanies([]);
            setFilteredCompanies([]);
        }
    };

	//handle changes to the form
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

	//send a post to the api to update the roles of a user
	const handleRoleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setIsSubmitting(true);

        // Check if the checkbox is checked or unchecked
        const isChecked = e.target.checked;

        try {
            let updatedUser = user;

            if (isChecked) {
                // If checked, assign the role
                const selectedRole = roles?.find((role) => role.name === e.target.name);
                if (selectedRole) {
                    await assignUserRole(user.id, selectedRole.name);
                    updatedUser.roles = [...user.roles, selectedRole];
                }
            } else {
                // If unchecked, remove the role
                const updatedRoles = user.roles.filter((role) => role.name !== e.target.name);
                await removeUserRole(user.id, e.target.name);
                updatedUser.roles = updatedRoles;
            }
            
        } catch (err) {
            console.error("Error updating user:", err);
            setError("Failed to update user.");
        } finally {
            setIsSubmitting(false);
        }
    };
	
	//activate or deactivate the selected users account
	const onActiveClick = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
			let updatedUser = user;
			if(!user.is_active){
				await activateUser(user.id);
				updatedUser.is_active = true;			
			}else{
				await deactivateUser(user.id);
				updatedUser.is_active = false;
			}
            
            onUserUpdated(updatedUser);
        } catch (err) {
            console.error("Error updating user:", err);
            setError("Failed to update user.");
        } finally {
            setIsSubmitting(false);
        }
    };



	//handle the form submission to update the user details
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
			let updatedUser;
			if (user.id !== 0) {
				updatedUser = await updateUser(user.id, formData);
			} else {
				updatedUser = await createUser(formData);
			}
			onUserUpdated(updatedUser);
        } catch (err) {
            console.error("Error updating user:", err);
            setError("Failed to update user.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
                <div className="flex items-center justify-center min-h-screen px-4">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black opacity-30" />
                    </Transition.Child>

                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                        <div className="bg-white rounded p-6 z-20">
                            {/* Modal heading */}
                            <DialogTitle className="text-xl font-semibold mb-4">
                                Edit {formData.first_name} {formData.last_name}
                            </DialogTitle>

                            {/* modal tabs, uses activetab useState react hook to transition state of the tab */}
                            <div className="flex space-x-4 mb-6 border-b">
                                <button onClick={() => setActiveTab("Personal")} className={`pb-2 ${activeTab === "Personal" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                    Personal Info
                                </button>
                                <button onClick={() => setActiveTab("Company")} className={`pb-2 ${activeTab === "Company" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                    Company Info
                                </button>
                                <button onClick={() => setActiveTab("Picture")} className={`pb-2 ${activeTab === "Picture" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                    Profile Picture
                                </button>
                                <button onClick={() => setActiveTab("Preferences")} className={`pb-2 ${activeTab === "Preferences" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                    Preferences
                                </button>
                                <button onClick={() => setActiveTab("Roles")} className={`pb-2 ${activeTab === "Roles" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                    Roles
                                </button>
                                <button onClick={() => setActiveTab("Subscriptions")} className={`pb-2 ${activeTab === "Subscriptions" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                    Subscriptions
                                </button>

                                {JSON.parse(sessionStorage.getItem("user") || "{}").roles.some((role: any) => role.name === "Commerce Management") && (
                                    <button onClick={() => setActiveTab("Commerce")} className={`pb-2 ${activeTab === "Commerce" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                        Commerce
                                    </button>
                                )}
                            </div>

                            <form onSubmit={handleSubmit} className="space-y-">
                                {/* personal info active tab */}
                                {activeTab === "Personal" && (
                                    <div className="grid grid-cols-3">
                                        {/* col 1 */}
                                        <div className="p-6">
                                            <div>
                                                <label htmlFor="first_name" className="block text-sm">
                                                    First Name
                                                </label>
                                                <input type="text" id="first_name" name="first_name" value={formData.first_name || ""} onChange={handleChange} placeholder="Enter first name" className="w-full p-2 border rounded" />
                                            </div>
                                            <div>
                                                <label htmlFor="last_name" className="block text-sm">
                                                    Last Name
                                                </label>
                                                <input type="text" id="last_name" name="last_name" value={formData.last_name || ""} onChange={handleChange} placeholder="Enter last name" className="w-full p-2 border rounded" />
                                            </div>
                                            <div>
                                                <label htmlFor="email" className="block text-sm">
                                                    Email
                                                </label>
                                                <input type="email" id="email" name="email" value={formData.email || ""} onChange={handleChange} placeholder="Enter email" className="w-full p-2 border rounded" />
                                            </div>
                                            <div>
                                                <label htmlFor="username" className="block text-sm">
                                                    Username
                                                </label>
                                                <input type="text" id="username" name="username" value={formData.username || ""} onChange={handleChange} placeholder="Enter username" className="w-full p-2 border rounded" />
                                            </div>
                                            {user.id === 0 && (
                                                <div>
                                                    <div>
                                                        <label htmlFor="password" className="block text-sm">
                                                            Password
                                                        </label>
                                                        <input type="password" id="password" name="password" value={formData.password || ""} onChange={handleChange} placeholder="Enter password" className="w-full p-2 border rounded" />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="password_confirmation" className="block text-sm">
                                                            Password Confirmation
                                                        </label>
                                                        <input
                                                            type="password"
                                                            id="password_confirmation"
                                                            name="password_confirmation"
                                                            value={formData.password_confirmation || ""}
                                                            onChange={handleChange}
                                                            placeholder="Enter password"
                                                            className="w-full p-2 border rounded"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* col 2 */}
                                        <div className="p-6">
                                            <div>
                                                <label htmlFor="address" className="block text-sm">
                                                    Address
                                                </label>
                                                <input type="text" id="address" name="address" value={formData.address || ""} onChange={handleChange} placeholder="Enter address" className="w-full p-2 border rounded" />
                                            </div>
                                            <div>
                                                <label htmlFor="city" className="block text-sm">
                                                    City
                                                </label>
                                                <input type="text" id="city" name="city" value={formData.city || ""} onChange={handleChange} placeholder="Enter city" className="w-full p-2 border rounded" />
                                            </div>
                                            <div>
                                                <label htmlFor="state" className="block text-sm">
                                                    State
                                                </label>
                                                <input type="text" id="state" name="state" value={formData.state || ""} onChange={handleChange} placeholder="Enter state" className="w-full p-2 border rounded" />
                                            </div>
                                            <div>
                                                {/* Make this a dropdoqn */}
                                                <label htmlFor="country" className="block text-sm">
                                                    Country
                                                </label>
                                                <input type="text" id="country" name="country" value={formData.country || ""} onChange={handleChange} placeholder="Enter country" className="w-full p-2 border rounded" />
                                            </div>
                                            <div>
                                                <label htmlFor="postal_code" className="block text-sm">
                                                    Post Code
                                                </label>
                                                <input type="text" id="postal_code" name="postal_code" value={formData.postal_code || ""} onChange={handleChange} placeholder="Enter post code " className="w-full p-2 border rounded" />
                                            </div>
                                        </div>

                                        {/* col 3 */}
                                        <div className="p-6">
                                            <div>
                                                <label htmlFor="phone_number" className="block text-sm">
                                                    Phone Number
                                                </label>
                                                <input type="text" id="phone_number" name="phone_number" value={formData.phone || ""} onChange={handleChange} placeholder="Enter phone number" className="w-full p-2 border rounded" />
                                            </div>
                                            <div>
                                                <label htmlFor="dob" className="block text-sm">
                                                    Date of Birth
                                                </label>
                                                <input
                                                    type="date"
                                                    id="dob"
                                                    name="dob"
                                                    value={formData.date_of_birth instanceof Date ? formData.date_of_birth.toISOString().substring(0, 10) : formData.date_of_birth || ""}
                                                    onChange={handleChange}
                                                    placeholder="Enter date of birth"
                                                    className="w-full p-2 border rounded"
                                                />
                                            </div>
                                            <div>
                                                {/* maybe change this to a dropdown? */}
                                                <label htmlFor="gender" className="block text-sm">
                                                    Gender
                                                </label>

                                                <input type="text" id="gender" name="gender" value={formData.gender || ""} onChange={handleChange} placeholder="Enter gender" className="w-full p-2 border rounded" />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Company info active tab */}
                                {activeTab === "Company" && (
                                    <div className="grid grid-cols-1">
                                        {/* col 1 */}
                                        <div className="p-6">
                                            <div>
											<label htmlFor="company" className="block text-sm">
                                                    Company
                                                </label>
                                                <select id="company" name="company" value={formData.company || ""} onChange={handleSelectChange} className="w-full p-2 border rounded" required>
                                                    <option value="" disabled>
                                                        Select Company
                                                    </option>
                                                    {companies.map((company) => (
                                                        <option key={company.name} value={company.name}>
                                                            {company.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div>
											<label htmlFor="company_code" className="block text-sm">
                                                    Company Code
                                                </label>
                                                <select id="company_code" name="company_code" value={formData.company_code || ""} onChange={handleSelectChange} className="w-full p-2 border rounded" required>
                                                    <option value="" disabled>
                                                        Select Company Code
                                                    </option>
                                                    {companies.map((company) => (
                                                        <option key={company.name} value={company.name}>
                                                            {company.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div>
                                                <label htmlFor="panel_key" className="block text-sm">
                                                    Panel Key
                                                </label>
                                                <input type="text" id="panel_key" name="panel_key" value={formData.panel_key || ""} onChange={handleChange} placeholder="Enter panel key" className="w-full p-2 border rounded" />
                                            </div>
                                            <div>
                                                <label htmlFor="reagents" className="block text-sm">
                                                    Reagents
                                                </label>
                                                <input type="text" id="reagents" name="reagents" value={formData.reagents || ""} onChange={handleChange} placeholder="Enter reagents" className="w-full p-2 border rounded" />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Profile picture tab */}
                                {activeTab === "Picture" && (
                                    <div className="grid grid-cols-1">
                                        <div className="p-6">
                                            <label htmlFor="profile_picture" className="block text-sm">
                                                Profile Picture
                                            </label>
                                            <input type="file" id="profile_picture" name="profile_picture" value={formData.profile_picture || ""} onChange={handleChange} placeholder="Enter last name" className="w-full p-2 border rounded" />
                                        </div>
                                    </div>
                                )}

                                {/* Preferences tab */}
                                {activeTab === "Preferences" && (
                                    <div className="grid grid-cols-1">
                                        <p>not active at the moment</p>
                                    </div>
                                )}

                                {/* Roles tab */}
                                {activeTab === "Roles" && (
                                    <div className="grid grid-cols-1">
                                        {roles?.map((role) => (
                                            <div key={role.id} className="">
                                                <input type="checkbox" id={role.id.toString()} name={role.name} onChange={handleRoleChange} checked={user.roles.some((userRole) => userRole.name === role.name)} />
                                                <label className="p-4" htmlFor={role.id.toString()}>
                                                    {role.name} ({role.guard_name})
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {/* Subscriptions tab */}
                                {activeTab === "Subscriptions" && (
                                    <div className="">
                                        <p>not active at the moment</p>
                                    </div>
                                )}

                                {/* Commerce Management*/}
                                {activeTab === "Commerce" && (
                                    <div className="">
                                        <p>
                                            not active at the moment, <br />
                                            This page will show a snap shot of the users commerce system and will link to a commerce section for full management
                                        </p>
                                    </div>
                                )}

                                {/* Gene codes user management */}

                                {error && <p className="text-red-500">{error}</p>}
                                <div className="flex justify-end space-x-2">
                                    {/* activate and deactivate the user */}
                                    <button type="button" onClick={onActiveClick} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">
                                        {user.is_active ? "Deactivate" : "Activate"}
                                    </button>
                                    <button type="button" onClick={onClose} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">
                                        Cancel
                                    </button>
                                    {user.id !== 0 ? (
                                        <button type="submit" disabled={isSubmitting} className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}>
                                            {isSubmitting ? "Saving..." : "Save Changes"}
                                        </button>
                                    ) : (
                                        <button type="submit" disabled={isSubmitting} className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}>
                                            {isSubmitting ? "Saving..." : "Create User"}
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default EditUserModal;
