import { fetchReagentList, createSample, Sample } from "../../../../api/labiqApi";
import React, { useState, useEffect } from "react";
import { assignSampleToPatient } from "../../../../api/patientApi";

interface CreateSampleSimpleModalProps {
    isOpen: boolean;
    onClose: () => void;
    setSamples: React.Dispatch<React.SetStateAction<Sample[]>>;
	patientEmail: string;
}

const CreateSampleSimpleModal: React.FC<CreateSampleSimpleModalProps> = ({ isOpen, onClose, setSamples, patientEmail }) => {
    const [client_sampleID, setclient_sampleID] = useState("");
    const [analysis_requested, setTestType] = useState<string[]>([]);
    const [delivery, setDelivery] = useState("");
    const [tracking_number, setTrackingNumber] = useState("");
    const [delivery_vendor, setDeliveryVendor] = useState("");
    const [reagentList, setReagentList] = useState<string[]>([]);

    useEffect(() => {
        const fetchReagents = async () => {
            try {
                const reagents = await fetchReagentList();
                setReagentList(reagents);
            } catch (err) {
                console.error("Error fetching reagents:", err);
            }
        };
        fetchReagents();
    }, [isOpen]);

    const handleSaveSample = async (sampleData: Sample) => {
        let newSample: Sample = {} as Sample;
        try {
            newSample = await createSample(sampleData);
            setSamples((prevSamples) => [...prevSamples, newSample]);
        } catch (err) {
            console.error("Error creating sample:", err);
        }
		return newSample.sampleID;
    };

    const handleSubmit = async () => {
		const user = JSON.parse(sessionStorage.getItem('user') || '{}');
	
		const sampleData = {
			client_sampleID,
			analysis_requested,
			delivery,
			client: user.company ,
			...(delivery === "courier" && {
				tracking_number,
				delivery_vendor,
			}),
		};
		await handleSaveSample(sampleData as Sample);
		await assignSampleToPatient(patientEmail, sampleData.client_sampleID);
		onClose();
	};

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
                <h2 className="text-xl font-bold mb-4">Create Sample</h2>
                {/* Input Field */}
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-1">Sample ID</label>
                    <input type="text" value={client_sampleID} onChange={(e) => setclient_sampleID(e.target.value)} required className="w-full border px-3 py-2 rounded" />
                </div>
                {/* Dropdown 1 */}
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-1">Test Types</label>
                    {reagentList.map((reagent) => (
                        <div key={reagent} className="flex items-center mb-2">
                            <input
                                type="checkbox"
                                id={reagent}
                                value={reagent}
                                checked={analysis_requested.includes(reagent)}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setTestType([...analysis_requested, reagent]);
                                    } else {
                                        setTestType(analysis_requested.filter((type) => type !== reagent));
                                    }
                                }}
                                className="mr-2"
                            />
                            <label htmlFor={reagent} className="text-gray-700">
                                {reagent}
                            </label>
                        </div>
                    ))}
                </div>

                {/* Dropdown 2 */}
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-1">Delivery Method</label>
                    <select value={delivery} onChange={(e) => setDelivery(e.target.value)} required className="w-full border px-3 py-2 rounded">
                        <option value="">Select an option</option>
                        <option value="courier">Courier</option>
                        <option value="internal">Internal</option>
                    </select>
                </div>

                {/* Conditional Fields */}
                {delivery === "courier" && (
                    <>
                        {/* Additional Input Field */}
                        <div className="mb-4">
                            <label className="block text-gray-700 font-medium mb-1">Tracking Number</label>
                            <input type="text" value={tracking_number} onChange={(e) => setTrackingNumber(e.target.value)} required className="w-full border px-3 py-2 rounded" />
                        </div>
                        {/* Additional Dropdown */}
                        <div className="mb-4">
                            <label className="block text-gray-700 font-medium mb-1">Delivery Vendor</label>
                            <select value={delivery_vendor} onChange={(e) => setDeliveryVendor(e.target.value)} required className="w-full border px-3 py-2 rounded">
                                <option value="">Select an option</option>
                                <option value="FedEX">FedEX</option>
                                <option value="UPS">UPS</option>
                            </select>
                        </div>
                    </>
                )}

                {/* Buttons */}
                <div className="flex justify-end">
                    <button type="button" onClick={onClose} className="mr-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">
                        Cancel
                    </button>
                    <button type="button" onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                        Create
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateSampleSimpleModal;
