// src/pages/inventory/ProductManagementPage.tsx

import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import ProductTable from "../../components/inventory/product/ProductTable";
import Pagination from "../../components/Pagination";
import ProductModal from "../../components/inventory/product/ProductModal";
import { Product, fetchProducts, createProduct, updateProduct, deleteProduct } from "../../api/productApi";

const ProductManagementPage: React.FC = () => {
    const [products, setProducts] = useState<Product[]>([]);
    const [totalProducts, setTotalProducts] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const PRODUCTS_PER_PAGE = 20;

    useEffect(() => {
        loadProducts();
    }, [currentPage, searchTerm]);

    const loadProducts = async () => {
        setIsLoading(true);
        try {
			const user = JSON.parse(sessionStorage.getItem("user") || "{}");
			const company = user.company;
            const { data, total } = await fetchProducts(currentPage, PRODUCTS_PER_PAGE, company, searchTerm);
            setProducts(data);
            setTotalProducts(total);
        } catch (error) {
            console.error("Error fetching products:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditClick = (product: Product) => {
        setSelectedProduct(product);
        setIsModalOpen(true);
    };

    const handleDeleteClick = async (product: Product) => {
        if (window.confirm(`Are you sure you want to delete the product "${product.name}"?`)) {
            try {
                await deleteProduct(product.product_id);
                loadProducts();
            } catch (error) {
                console.error("Error deleting product:", error);
            }
        }
    };

    const handleModalClose = () => {
        setSelectedProduct(null);
        setIsModalOpen(false);
    };

    const handleProductSave = async (productData: Partial<Product>) => {
        try {
            if (selectedProduct) {
                // Update product
                await updateProduct(selectedProduct.product_id, productData);
            } else {
                // Create new product
                await createProduct(productData);
            }
            loadProducts();
            handleModalClose();
        } catch (error) {
            console.error("Error saving product:", error);
        }
    };

    const handleAddProduct = () => {
        setSelectedProduct(null);
        setIsModalOpen(true);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    return (
        <Layout>
            <h1 className="text-2xl font-bold mb-4">Product Management</h1>
            <div className="mb-4 flex justify-between">
                <button onClick={handleAddProduct} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                    Add Product
                </button>
                <input type="text" placeholder="Search products..." value={searchTerm} onChange={handleSearchChange} className="border px-4 py-2 rounded" />
            </div>
            {isLoading ? (
                <p>Loading products...</p>
            ) : (
                <>
                    <ProductTable products={products} onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} />
                    <Pagination currentPage={currentPage} totalItems={totalProducts} itemsPerPage={PRODUCTS_PER_PAGE} onPageChange={setCurrentPage} />
                </>
            )}
            {isModalOpen && <ProductModal isOpen={isModalOpen} onClose={handleModalClose} product={selectedProduct} onSave={handleProductSave} />}
        </Layout>
    );
};

export default ProductManagementPage;
