import React from "react";
import Layout from "../components/Layout";

const ProfilePage: React.FC = () => {
    return (
        <Layout>
            <div className="profile">
                <h1>Welcome to your profile</h1>
                {/* Profile content goes here */}
				<div className="flex flex-col items-center justify-center h-full">
                <div className="bg-gray-100 p-6 rounded shadow-md text-center">
                    <p className="text-lg mb-4">This function isn't ready yet.</p>
                    <button onClick={() => (window.location.href = "/")} className="bg-blue-500 text-white px-4 py-2 rounded">
                        Back to Dashboard
                    </button>
                </div>
            </div>
            </div>
        </Layout>
    );
};

export default ProfilePage;
