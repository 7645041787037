import React, { ChangeEvent } from "react";
import { Sample } from "../../../../api/labiqApi";


interface SampleLabProps {
    formData: Partial<Sample>;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const SampleLab: React.FC<SampleLabProps> = ({ formData, handleChange, handleSelectChange}) => {

    return (
        <div className="grid grid-cols-2">
            {/* col 1 */}
            <div className="p-6">
                <div className="p-2">
                    <label htmlFor="QC_date" className="block text-sm">
                        QC Date
                    </label>
                    <input type="date" id="QC_date" name="QC_date" value={formData.QC_date || ""} onChange={handleChange} placeholder="Enter QC Date" className="w-full p-2 border rounded" />
                </div>
                <div className="p-2">
                    <label htmlFor="concentration" className="block text-sm">
                        Concentration
                    </label>
                    <input type="text" id="concentration" name="concentration" value={formData.concentration || ""} onChange={handleChange} placeholder="Enter concentration" className="w-full p-2 border rounded" />
                </div>
                <div className="p-2">
                    <label htmlFor="OD" className="block text-sm">
                        Absorbance 260/280
                    </label>
                    <input type="text" id="OD" name="OD" value={formData.OD || ""} onChange={handleChange} placeholder="Enter Absorbance 260/280" className="w-full p-2 border rounded" />
                </div>
                <div className="p-2">
                    <label htmlFor="OD2" className="block text-sm">
                        Absorbance 260/230
                    </label>
                    <input type="text" id="OD2" name="OD2" value={formData.OD2 || ""} onChange={handleChange} placeholder="Enter Absorbance 260/230" className="w-full p-2 border rounded" />
                </div>
                <div className="p-2">
                    <label htmlFor="plated_state" className="block text-sm">
                        Plated Status
                    </label>
                    <select id="plated_state" name="plated_state" value={formData.plated_state || ""} onChange={handleSelectChange} className="w-full p-2 border rounded">
                        <option value="" disabled>
                            Select Plated Status
                        </option>
                        <option value="plated">Plated</option>
                        <option value="next_run">Next Run</option>
                    </select>
                </div>
            </div>
            <div className="p-6">
                <div className="p-2">
                    <label htmlFor="eProtocol" className="block text-sm">
                        Enhanced Protocol
                    </label>
                    <select id="eProtocol" name="eProtocol" value={formData.eProtocol || ""} onChange={handleSelectChange} className="w-full p-2 border rounded">
                        <option value="" disabled>
                            Select Protocol
                        </option>
                        <option value="concentration">Concentration</option>
                        <option value="dilution">Dilution</option>
                        <option value="purification">Purification</option>
                    </select>
                </div>
                <div className="p-2">
                    <label htmlFor="eP_concentration" className="block text-sm">
                        New Concentration
                    </label>
                    <input type="text" id="eP_concentration" name="eP_concentration" value={formData.eP_concentration || ""} onChange={handleChange} placeholder="Enter New Concentration" className="w-full p-2 border rounded" />
                </div>
                <div className="p-2">
                    <label htmlFor="eP_OD" className="block text-sm">
                        New Absorbance 260/280
                    </label>
                    <input type="text" id="eP_OD" name="eP_OD" value={formData.eP_OD || ""} onChange={handleChange} placeholder="Enter New Absorbance 260/280" className="w-full p-2 border rounded" />
                </div>
                <div className="p-2">
                    <label htmlFor="eP_OD2" className="block text-sm">
                        New Absorbance 260/230
                    </label>
                    <input type="text" id="eP_OD2" name="eP_OD2" value={formData.eP_OD2 || ""} onChange={handleChange} placeholder="Enter New Absorbance 260/230" className="w-full p-2 border rounded" />
                </div>
            </div>
        </div>
    );
};

export default SampleLab;
