import axios, { AxiosInstance } from "axios";
const API_BASE_URL = process.env.REACT_APP_LABIQ_SERVER;

export interface Sample {
    sampleID: number;
    client: string;
    jobID: number;
    client_runID: string | null;
    client_sampleID: string;
    job_sequence: number;
    sampleType: string;
    QC_date: string | null;
    protocol: string;
    concentration: number | null;
    OD: number | null;
    OD2: number | null;
    eProtocol: string;
    eP_concentration: number | null;
    eP_OD: number | null;
    eP_OD2: number | null;
    dProtocol: string | null;
    gel_pic: string | null;
    gel_lane: string | null;
    OA_date: string | null;
    OA_plate: string | null;
    OA_call_rate: number | null;
    status: string;
    recollect_notice: string | null;
    reported: boolean | null;
    reported_date: string | null;
    plated_state: string | null;
    analysis_requested: string[];
    turnaround: string | null;
    date_expected: string | null;
    volume: number | null;
    storage: string | null;
    comments: string;
    QC_notice: string | null;
    create_date: string | null;
    lab_location: string | null;
    available_material: string | null;
    batch_id: number | null;
	delivery: string | null;
	tracking_number: string | null;
	delivery_vendor: string | null;
}

export interface Batch{
	batch_id: number;
	batch_client_id: string;
	batch_delivery_option: number;
	batch_tracking_no: string;
	batch_vendor: string;
	batch_create_date: string;
	batch_submission: number;
	batch_user_id: string;
	batch_company: string;
	batch_sample_count: number;
	samples: BatchSample[];
}

export interface BatchSample{
	bs_id : number,
	bs_sample_id : string,
	bs_sequence : string,
	bs_test_type : string[],
	bs_collection_type : string,
	bs_batch_id : number
}

////////////////////////// sample management api calls
export const fetchSamples = async (
    page: number,
    perPage: number,
    company?: string,
    sortBy: string = "sampleID", // default sort column
    sortOrder: string = "desc", // default sort order
	search?: string
): Promise<{
    data: Sample[];
    total: number;
}> => {
    const response = await axios.post(
        `${API_BASE_URL}/getSamples`,
        {},
        {
            params: {
                page,
                per_page: perPage,
                company,
                sort_by: sortBy,
                sort_order: sortOrder,
				search: search,
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
            },
        }
    );

    return {
        data: response.data.data,
        total: response.data.total,
    };
};

export const fetchSample = async (sampleID: string): Promise<Sample> => {
    const response = await axios.get<Sample>(`${API_BASE_URL}/sample/${sampleID}`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return response.data;
};

export const fetchCompanyList = async (): Promise<string[]> => {
    const response = await axios.get<{ results: string[] }>(`${API_BASE_URL}/getCompanies`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return response.data.results;
};

export const fetchReagentList = async (company?: string): Promise<string[]> => {
    let url = `${API_BASE_URL}/getRegent`;
    if (company) {
        url += `?company=${encodeURIComponent(company)}`;
    } else {
        url += "?full=true";
    }
    const response = await axios.get<{ results: string[] }>(url, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return response.data.results;
};

export const updateSample = async (sampleID: number, sample: Sample) => {
    await axios.put(
        `${API_BASE_URL}/sample/${sampleID}`,
        {
            sample,
            username: JSON.parse(sessionStorage.getItem("user") || "{}").username,
        },
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
            },
        }
    );
    return sample;
};

export const createSample = async (sample: Sample) => {
	const response = await axios.post(
		`${API_BASE_URL}/sample`,
		{
			sample,
			username: JSON.parse(sessionStorage.getItem("user") || "{}").username,
		},
		{
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		}
	);
	return response.data.sample;
};

export const deleteSample = async (sampleID: number) => {
    
        await axios.delete(`${API_BASE_URL}/sample/${sampleID}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
            },
        });
    
};

export const uploadOmnibeadData = async (formData: FormData) => {
    const response = await axios.post(`${API_BASE_URL}/omnibead/upload`, formData, {
        headers: {
            // Remove 'Content-Type' header
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return response.data;
};

export const importOmnibeadData = async (data: { omnibead_batchID: string; }) => {
   
        const response = await axios.post(`${API_BASE_URL}/omnibead/import`, data, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
            },
        });
        return response.data;
    
};

export const translateOmnibeadData = async (data: { omnibead_batchID: string; translation_mode: string; }) => {
    
        const response = await axios.post(`${API_BASE_URL}/omnibead/translate`, data, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
            },
        });
        return response.data;
    
};



////////////////////////// batch management api calls
export const fetchBatches = async (
	page: number,
	perPage: number,
	company?: string,
	sortBy: string = "batch_id",
	sortOrder: string = "desc" 
): Promise<{
	data: Batch[];
	total: number;
}> => {
    const response = await axios.get(
        `${API_BASE_URL}/batches`,
        {
            params: {
                page,
                per_page: perPage,
                company,
                sort_by: sortBy,
                sort_order: sortOrder,
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
            },
        }
    );

	return {
		data: response.data.data,
		total: response.data.total,
	};
};

export const fetchBatch = async (batch_id: number): Promise<Batch> => {
	const response = await axios.get<Batch>(`${API_BASE_URL}/batches/${batch_id}`, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};

export const createBatch = async (batch: Batch) => {
	await axios.post(
		`${API_BASE_URL}/batches`,
		{
			batch_client_id: batch.batch_client_id,
			batch_delivery_option: batch.batch_delivery_option || "",
			batch_tracking_no: batch.batch_tracking_no || "",
			batch_vendor: batch.batch_vendor || "",
			user_id: JSON.parse(sessionStorage.getItem("user") || "{}").id,
			batch_company: batch.batch_company,
			samples: batch.samples || [],
		},
		{
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		}
	);
	return batch;
};

export const updateBatch = async (batch_id: number, batch: Batch) => {
	await axios.put(
		`${API_BASE_URL}/batches/${batch_id}`,
		{
			batch_client_id: batch.batch_client_id,
			batch_delivery_option: batch.batch_delivery_option !== null ? batch.batch_delivery_option : "",
			batch_tracking_no: batch.batch_tracking_no || "",
			batch_vendor: batch.batch_vendor || "",
			user_id: JSON.parse(sessionStorage.getItem("user") || "{}").id,
			batch_company: batch.batch_company,
			samples: batch.samples || [],
		},
		{
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		}
	);
	return batch;
};

export const submitBatch = async (batch_id: number) => {
	await axios.post(
		`${API_BASE_URL}/batches/${batch_id}/submit`,
		{},
		{
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
			},
		}
	);
};

export const deleteBatch = async (batch_id: number) => {
	await axios.delete(`${API_BASE_URL}/batches/${batch_id}`, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
};