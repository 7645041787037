// src/pages/inventory/StockManagementPage.tsx

import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Layout from "../../components/Layout";
import StockTable from "../../components/inventory/stock/StockTable";
import Pagination from "../../components/Pagination";
import StockModal from "../../components/inventory/stock/StockModal";
import { Stock, fetchStockEntries, createStockEntry, updateStockEntry, deleteStockEntry } from "../../api/stockApi";
import { fetchProduct, Product } from "../../api/productApi";

const StockManagementPage: React.FC = () => {
    const { productId } = useParams<{ productId?: string }>();
    const [stockEntries, setStockEntries] = useState<Stock[]>([]);
    const [totalStockEntries, setTotalStockEntries] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedStock, setSelectedStock] = useState<Stock | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [product, setProduct] = useState<Product | null>(null);

    const STOCKS_PER_PAGE = 20;

    useEffect(() => {
        loadStockEntries();
        if (productId) {
            loadProduct(parseInt(productId, 10));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const loadProduct = async (id: number) => {
        try {
            const productData = await fetchProduct(id);
            setProduct(productData);
        } catch (error) {
            console.error("Error fetching product:", error);
        }
    };

    const loadStockEntries = async () => {
        setIsLoading(true);
        try {
            const { data, total } = await fetchStockEntries(currentPage, STOCKS_PER_PAGE, {
                product_id: productId ? parseInt(productId, 10) : undefined,
            });
            setStockEntries(data);
            setTotalStockEntries(total);
        } catch (error) {
            console.error("Error fetching stock entries:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditClick = (stock: Stock) => {
        setSelectedStock(stock);
        setIsModalOpen(true);
    };

    const handleDeleteClick = async (stock: Stock) => {
        if (window.confirm(`Are you sure you want to delete the stock entry with ID "${stock.stock_id}"?`)) {
            try {
                await deleteStockEntry(stock.stock_id);
                loadStockEntries();
            } catch (error) {
                console.error("Error deleting stock entry:", error);
            }
        }
    };

    const handleModalClose = () => {
        setSelectedStock(null);
        setIsModalOpen(false);
    };

    const handleStockSave = async (stockData: Partial<Stock>) => {
        try {
            if (selectedStock) {
                // Update stock entry
                await updateStockEntry(selectedStock.stock_id, stockData);
            } else {
                // Create new stock entry
                await createStockEntry(stockData);
            }
            loadStockEntries();
            handleModalClose();
        } catch (error) {
            console.error("Error saving stock entry:", error);
        }
    };

    const handleAddStock = () => {
        setSelectedStock(null);
        setIsModalOpen(true);
    };

    return (
        <Layout>
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Stock Management {product ? `for ${product.name}` : ""}</h1>
                <Link to="/inventory/products" className="text-blue-500 hover:underline">
                    Back to Products
                </Link>
            </div>
            <div className="mb-4">
                <button onClick={handleAddStock} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                    Add Stock Entry
                </button>
            </div>
            {isLoading ? (
                <p>Loading stock entries...</p>
            ) : (
                <>
                    <StockTable stockEntries={stockEntries} onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} />
                    <Pagination currentPage={currentPage} totalItems={totalStockEntries} itemsPerPage={STOCKS_PER_PAGE} onPageChange={setCurrentPage} />
                </>
            )}
            {isModalOpen && <StockModal isOpen={isModalOpen} onClose={handleModalClose} stock={selectedStock} onSave={handleStockSave} productId={productId ? parseInt(productId, 10) : undefined} />}
        </Layout>
    );
};

export default StockManagementPage;
