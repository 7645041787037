import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; 
import Layout from "../../components/Layout";
import { fetchSample, fetchSamples, Sample } from '../../api/labiqApi'; 
import SampleTable from '../../components/labiq/sampleManagement/SampleTable';
import ActionButtons from '../../components/labiq/sampleManagement/SampleTrackingButtons'; 
import SampleModal from '../../components/labiq/sampleManagement/SampleModal';
import OmnibeadModal from '../../components/labiq/omnibeadUploadForm';
import Pagination from "../../components/Pagination";

const SampleTrackingPage: React.FC = () => {
    const [samples, setSamples] = useState<Sample[]>([]);
    const [totalSamples, setTotalSamples] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
	const [sortBy, setSortBy] = useState<string>('sampleID');
    const [sortOrder, setSortOrder] = useState<string>('desc');
    const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const[isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
    const [selectedSamples, setSelectedSamples] = useState<number[]>([]);
	const [selectedSampleClientId, setSelectedSampleClientId] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);
	const [searchTerm, setSearchTerm] = useState<string>("");

	const { id } = useParams<{ id: string }>();

    const SAMPLES_PER_PAGE = 30;

    // Fetch sample data and handle modal open logic
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (id) {

                        const sample = await fetchSample(id);
                        setSamples([sample]);
                        handleEditClick(sample.sampleID); // Open the modal with that sample ID
                    
                } else {
                    fetchSampleData("");
                }
            } catch (err) {
                console.error("Error fetching data:", err);
                setError("Failed to load samples.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, currentPage, sortBy, sortOrder]); // Remove 'samples' from the dependency array to prevent re-render loops

    const fetchSampleData = async (searchTerm: string) => {
        setIsLoading(true);
        setError(null);
        try {
			const userInfo = JSON.parse(sessionStorage.getItem('user') || '{}');
            const company = userInfo.company;
            const { data, total } = await fetchSamples(currentPage, SAMPLES_PER_PAGE, company, sortBy, sortOrder, searchTerm);
            setSamples(data);
            setTotalSamples(total);
        } catch (err) {
            console.error("Error fetching samples:", err);
            setError("Failed to load samples.");
        } finally {
            setIsLoading(false);
        }
    };

	// Handle sorting of the page data
	const handleSort = (column: string) => {
        if (column === sortBy) {
            setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
    };

	// Page functions
	const handleEditClick = (sampleID: number) => {
        setSelectedSamples([sampleID]);
        setIsModalOpen(true);
    };

	const handleNewSampleClick = () => {
        setSelectedSamples([0]);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setSelectedSamples([]);
		setSelectedSampleClientId([]);
        setIsModalOpen(false);
		setIsUploadModalOpen(false);
    };

    const handleFormSubmission = () => {
        // Update user in the list
		fetchSampleData("");
		handleModalClose();
    };

	const handleOmnibeadModalOpen = () => {
		setIsModalOpen(false);
		setIsUploadModalOpen(true); // Open the upload modal
	};

    return (
        <Layout>
            <div className="p-6">
                <h1 className="text-2xl font-bold mb-4">Sample Tracking</h1>
                {/* Action Buttons */}
                <ActionButtons selectedSamples={selectedSamples} selectedSampleClientId={selectedSampleClientId} refreshSamples={fetchSampleData} editClick={handleEditClick} newSample={handleNewSampleClick} omnibeadUpload={handleOmnibeadModalOpen} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                {/* Sample Table */}
                {isLoading ? (
                    <p className="text-gray-600">Loading samples...</p>
                ) : error ? (
                    <p className="text-red-500">{error}</p>
                ) : (
                    <>
                        <SampleTable samples={samples} selectedSamples={selectedSamples} setSelectedSamples={setSelectedSamples} onSort={handleSort} sortBy={sortBy} setSelectedSampleClientId={setSelectedSampleClientId} selectedSampleClientId={selectedSampleClientId}/>
                        <Pagination currentPage={currentPage} totalItems={totalSamples} itemsPerPage={SAMPLES_PER_PAGE} onPageChange={setCurrentPage} />
                    </>
                )}

                {/* Show the modal to edit or create a sample */}
                {isModalOpen && selectedSamples.length === 1 && (
                    <SampleModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                        sample={
                            selectedSamples[0] === 0
                                ? {
                                      sampleID: 0,
                                      client: "",
                                      jobID: 0,
                                      client_runID: null,
                                      client_sampleID: "",
                                      job_sequence: 0,
                                      sampleType: "",
                                      QC_date: null,
                                      protocol: "",
                                      concentration: null,
                                      OD: null,
                                      OD2: null,
                                      eProtocol: "",
                                      eP_concentration: null,
                                      eP_OD: null,
                                      eP_OD2: null,
                                      dProtocol: null,
                                      gel_pic: null,
                                      gel_lane: null,
                                      OA_date: null,
                                      OA_plate: null,
                                      OA_call_rate: null,
                                      status: "",
                                      recollect_notice: null,
                                      reported: null,
                                      reported_date: null,
                                      plated_state: null,
                                      analysis_requested: [],
                                      turnaround: null,
                                      date_expected: null,
                                      volume: null,
                                      storage: null,
                                      comments: "",
                                      QC_notice: null,
                                      create_date: null,
                                      lab_location: null,
                                      available_material: null,
                                      batch_id: null,
									  delivery: null,
									  tracking_number: null,
									  delivery_vendor: null,
                                  }
                                : samples.find((s) => s.sampleID === selectedSamples[0])!
                        }
                        onFormSubmission={handleFormSubmission}
                    />
                )}

                {/* Show the modal to upload an omnibead file */}
                {isUploadModalOpen && (<OmnibeadModal isOpen={isUploadModalOpen} onClose={handleModalClose} onFormSubmission={handleFormSubmission} />)}
            </div>
        </Layout>
    );
};

export default SampleTrackingPage;
