// src/pages/orders/SalesOrderManagementPage.tsx

import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import SalesOrderTable from "../../components/inventory/ordering/SalesOrderTable";
import Pagination from "../../components/Pagination";
import SalesOrderModal from "../../components/inventory/ordering/SalesOrderModal";
import { fetchSalesOrders, createSalesOrder, updateSalesOrder, deleteSalesOrder, SalesOrder } from "../../api/salesOrderApi";

const SalesOrderManagementPage: React.FC = () => {
    const [orders, setOrders] = useState<SalesOrder[]>([]);
    const [totalOrders, setTotalOrders] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedOrder, setSelectedOrder] = useState<SalesOrder | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const ORDERS_PER_PAGE = 20;

    useEffect(() => {
        loadOrders();
    }, [currentPage]);

    const loadOrders = async () => {
        setIsLoading(true);
        try {
            const { data, total } = await fetchSalesOrders({ page: currentPage, per_page: ORDERS_PER_PAGE });
            setOrders(data);
            setTotalOrders(total);
        } catch (error) {
            console.error("Error fetching sales orders:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddOrder = () => {
        setSelectedOrder(null);
        setIsModalOpen(true);
    };

    const handleEditOrder = (order: SalesOrder) => {
        setSelectedOrder(order);
        setIsModalOpen(true);
    };

    const handleDeleteOrder = async (order: SalesOrder) => {
        if (window.confirm(`Are you sure you want to delete Sales Order #${order.sales_order_id}?`)) {
            try {
                await deleteSalesOrder(order.sales_order_id!);
                loadOrders();
            } catch (error) {
                console.error("Error deleting sales order:", error);
            }
        }
    };

    const handleModalClose = () => {
        setSelectedOrder(null);
        setIsModalOpen(false);
    };

    const handleOrderSave = async (orderData: SalesOrder) => {
        try {
            if (selectedOrder) {
                await updateSalesOrder(selectedOrder.sales_order_id!, orderData);
            } else {
                await createSalesOrder(orderData);
            }
            loadOrders();
            handleModalClose();
        } catch (error) {
            console.error("Error saving sales order:", error);
        }
    };

    return (
        <Layout>
            <h1 className="text-2xl font-bold mb-4">Sales Order Management</h1>
            <div className="mb-4">
                <button onClick={handleAddOrder} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                    Add Sales Order
                </button>
            </div>
            {isLoading ? (
                <p>Loading sales orders...</p>
            ) : (
                <>
                    <SalesOrderTable orders={orders} onEdit={handleEditOrder} onDelete={handleDeleteOrder} />
                    <Pagination currentPage={currentPage} totalItems={totalOrders} itemsPerPage={ORDERS_PER_PAGE} onPageChange={setCurrentPage} />
                </>
            )}
            {isModalOpen && <SalesOrderModal isOpen={isModalOpen} onClose={handleModalClose} order={selectedOrder} onSave={handleOrderSave} />}
        </Layout>
    );
};

export default SalesOrderManagementPage;
