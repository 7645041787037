// src/components/icons/DashboardIcon.tsx
import React from "react";

const DashboardIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 3h8v8H3V3zm10 0h8v4h-8V3zM3 13h8v8H3v-8zm10 4h8v4h-8v-4z"
    />
  </svg>
);

export default DashboardIcon;
