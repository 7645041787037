// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

//import pages
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";

import SettingsPage from "./pages/SettingsPage";
import ProfilePage from "./pages/Profile";

//User pages
import RoleManagementPage from "./pages/user/RoleManagementPage";
import UserManagementPage from "./pages/user/UserManagementPage";
import ApplicationManagementPage from "./pages/user/ApplicationManagementPage";
import SubscriptionManagementPage from "./pages/user/SubscriptionManagementPage";
import PlanManagementPage from "./pages/user/PlanManagementPage";

//Labiq Pages
import SampleTracking from "./pages/labiq/SampleTrackingPage";
import BatchManagement from "./pages/labiq/BatchManagementPage";

//import the logout component
import Logout from "./components/Logout";

//Inventory/commerce system
import ProductManagementPage from "./pages/inventory/ProductManagementPage";
import StockManagementPage from './pages/inventory/StockManagementPage';
import StockAssignmentPage from './pages/inventory/StockAssignmentPage';
import SupplierManagementPage from './pages/inventory/SupplierManagementPage';
import PurchaseOrderManagementPage from "./pages/inventory/PurchaseOrderManagementPage";
import SalesOrderManagementPage from "./pages/inventory/SalesOrderManagementPage";
import OrderOverviewPage from "./pages/inventory/OrderingOverviewPage";
import PatientManagementPage from "./pages/healthcare/PatientsPage";


//Create the private route to check for an authApiKey in the local storage
//If there is no API key then redirect to the login page.
const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const apiKey = sessionStorage.getItem("apiKey");
    return apiKey ? children : <Navigate to="/login" replace />;
};

//makes sure the user has the lab management role before allowing access to the lab management pages and the corect api key
const PractitionerRoute = ({ children }: { children: JSX.Element }) => {
	const apiKey = sessionStorage.getItem("apiKey");
	const user = JSON.parse(sessionStorage.getItem("user") || "{}");
	const hasLabManagementRole = user.roles?.some((role: any) => role.name === 'Practitioner');
	return (apiKey && hasLabManagementRole) ? children : <Navigate to="/login" replace />;
};

//makes sure the user has the lab management role before allowing access to the lab management pages and the corect api key
const LabRoute = ({ children }: { children: JSX.Element }) => {
	const apiKey = sessionStorage.getItem("apiKey");
	const user = JSON.parse(sessionStorage.getItem("user") || "{}");
	const hasLabManagementRole = user.roles?.some((role: any) => role.name === 'Lab Management' || role.name === "Practitioner");
	return (apiKey && hasLabManagementRole) ? children : <Navigate to="/login" replace />;
};

function App() {
    return (
        <Router>
            <Routes>
                {/* Public Route */}
                <Route path="/login" element={<LoginPage />} />

                {/* Protected Routes */}
                <Route
                    path="/"
                    element={
                        <PrivateRoute>
                            <DashboardPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/logout"
                    element={
                        <PrivateRoute>
                            <Logout />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/settings"
                    element={
                        <PrivateRoute>
                            <SettingsPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/profile"
                    element={
                        <PrivateRoute>
                            <ProfilePage />
                        </PrivateRoute>
                    }
                />

                {/* User Management Routes */}
                <Route
                    path="/users"
                    element={
                        <PrivateRoute>
                            <UserManagementPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/roles"
                    element={
                        <PrivateRoute>
                            <RoleManagementPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/applications"
                    element={
                        <PrivateRoute>
                            <ApplicationManagementPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/subscriptions"
                    element={
                        <PrivateRoute>
                            <SubscriptionManagementPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/plans"
                    element={
                        <PrivateRoute>
                            <PlanManagementPage />
                        </PrivateRoute>
                    }
                />

				{/* Healthcare */}
				<Route
                    path="/healthcare/patients"
                    element={
                        <PractitionerRoute>
                            <PatientManagementPage />
                        </PractitionerRoute>
                    }
                />

                {/* Lab Management Routes */}
                <Route
                    path="/labiq/sample-tracking/:id?"
                    element={
                        <LabRoute>
                            <SampleTracking />
                        </LabRoute>
                    }
                />
                <Route
                    path="/labiq/batch-management"
                    element={
                        <LabRoute>
                            <BatchManagement />
                        </LabRoute>
                    }
                />

                {/* Inventory management */}
                <Route
                    path="/inventory/products"
                    element={
                        <PrivateRoute>
                            <ProductManagementPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/inventory/products/:productId/stock"
                    element={
                        <PrivateRoute>
                            <StockManagementPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/inventory/stock/:stockId/assignments"
                    element={
                        <PrivateRoute>
                            <StockAssignmentPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/inventory/ordering"
                    element={
                        <PrivateRoute>
                            <OrderOverviewPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/inventory/ordering/purchase-orders"
                    element={
                        <PrivateRoute>
                            <PurchaseOrderManagementPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/inventory/ordering/sales-orders"
                    element={
                        <PrivateRoute>
                            <SalesOrderManagementPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/inventory/suppliers"
                    element={
                        <PrivateRoute>
                            <SupplierManagementPage />
                        </PrivateRoute>
                    }
                />

                {/* Fallback Route */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
}

export default App;
