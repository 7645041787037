import React, { useState, useEffect } from "react";
import { StockAssignment } from "../../../api/stockAssignmentApi";
import { fetchCompanies, Company } from "../../../api/companyApi";
import { fetchUsers, User } from "../../../api/userApi";

interface StockAssignmentModalProps {
    isOpen: boolean;
    onClose: () => void;
    assignment: StockAssignment | null;
    onSave: (assignmentData: StockAssignment) => void;
    stockId: number;
}

const StockAssignmentModal: React.FC<StockAssignmentModalProps> = ({ isOpen, onClose, assignment, onSave, stockId }) => {
    const [formData, setFormData] = useState<Partial<StockAssignment>>({ stock_id: stockId });
    const [assigneeType, setAssigneeType] = useState<string>("user");
    const [companies, setCompanies] = useState<Company[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

	useEffect(() => {
		setFormData(assignment || { stock_id: stockId });
		if (assignment && assignment.assignee_type) {
			setAssigneeType(assignment.assignee_type);
		} else {
			setAssigneeType("user");
		}
	}, [assignment, stockId]);

	useEffect(() => {
		if (assigneeType === "company" && filteredCompanies.length > 0) {
            setFormData((prevData) => ({ ...prevData, assignee_id: filteredCompanies[0].id.toString() }));

		} else if (assigneeType === "user" && filteredUsers.length > 0) {
			setFormData((prevData) => ({ ...prevData, assignee_id: filteredUsers[0].uuid }));
		}
		setFormData((prevData) => ({ ...prevData, assignee_type: assigneeType }));
		setFormData((prevData) => ({ ...prevData, status: "active" }));
	}, [assigneeType, filteredCompanies, filteredUsers]);

    useEffect(() => {
        if (assigneeType === "company") {
            fetchCompaniesList();
        } else if (assigneeType === "user") {
            fetchUsersList();
        }
        setSearchTerm("");
    }, [assigneeType]);

    const fetchCompaniesList = async () => {
        try {
            const data = await fetchCompanies();
            setCompanies(data || []);
            setFilteredCompanies(data || []);
        } catch (error) {
            console.error("Error fetching companies:", error);
            setCompanies([]);
            setFilteredCompanies([]);
        }
    };

    const fetchUsersList = async () => {
        try {
            const {data, total} = await fetchUsers(1,2);
            setUsers(data || []);
            setFilteredUsers(data || []);
        } catch (error) {
            console.error("Error fetching users:", error);
            setUsers([]);
            setFilteredUsers([]);
        }
    };

    const handleAssigneeTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setAssigneeType(value);
        setFormData((prevData) => ({ ...prevData, assignee_type: value, assignee_id: "" }));
        setSearchTerm("");
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);

        if (assigneeType === "company") {
            const filtered = companies.filter((company) => company.name.toLowerCase().includes(value.toLowerCase()));
            setFilteredCompanies(filtered);
        } else if (assigneeType === "user") {
            const filtered = users.filter((user) => user.email.toLowerCase().includes(value.toLowerCase()) || user.first_name.toLowerCase().includes(value.toLowerCase()));
            setFilteredUsers(filtered);
        }
    };

    const handleAssigneeIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setFormData((prevData) => ({ ...prevData, assignee_id: value }));
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const numberValue = value === "" ? undefined : parseInt(value, 10);
        setFormData((prevData) => ({ ...prevData, [name]: numberValue }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSave(formData as StockAssignment);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-md w-full max-w-lg">
                <h2 className="text-xl font-bold mb-4">{assignment ? "Edit Assignment" : "Add Assignment"}</h2>
                <form onSubmit={handleSubmit}>
                    {/* Assignee Type */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Assignee Type</label>
                        <select name="assignee_type" value={assigneeType} onChange={handleAssigneeTypeChange} required className="w-full border px-3 py-2 rounded">
                            <option value="user">User (B2C)</option>
                            <option value="company">Company (B2B)</option>
                        </select>
                    </div>
                    {/* Assignee ID */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Assignee</label>
                        <input type="text" placeholder="Search..." value={searchTerm} onChange={handleSearchChange} className="w-full border px-3 py-2 rounded mb-2" />
                        <select name="assignee_id" value={formData.assignee_id || ""} onChange={handleAssigneeIdChange} required className="w-full border px-3 py-2 rounded" size={5}>
                            {assigneeType === "company" &&
                                filteredCompanies.map((company) => (
                                    <option key={company.id} value={company.id}>
                                        {company.name}
                                    </option>
                                ))}
                            {assigneeType === "user" &&
                                (filteredUsers || []).map((user) => (
                                    <option key={user.uuid} value={user.uuid}>
                                        {user.email} - {user.first_name} {user.last_name}
                                    </option>
                                ))}
                        </select>
                    </div>
                    {/* Quantity Assigned */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Quantity Assigned</label>
                        <input name="quantity_assigned" type="number" value={formData.quantity_assigned !== undefined ? formData.quantity_assigned : ""} onChange={handleNumberChange} required className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* Status */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Status</label>
                        <select name="status" value={formData.status || "active"} onChange={handleChange} className="w-full border px-3 py-2 rounded">
                            <option value="active">Active</option>
                            <option value="used">Used</option>
                        </select>
                    </div>
                    {/* Notes */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Notes</label>
                        <textarea name="notes" value={formData.notes || ""} onChange={handleChange} className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* Buttons */}
                    <div className="flex justify-end">
                        <button type="button" onClick={onClose} className="mr-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">
                            Cancel
                        </button>
                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                            {assignment ? "Update" : "Create"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default StockAssignmentModal;
