import React from 'react';
import { Role } from '../../api/rolesApi';

interface RoleTableProps {
	roles: Role[];
	onEditClick: (role: Role) => void;
}

const RoleTable: React.FC<RoleTableProps> = ({ roles, onEditClick }) => {
	// Add a check to handle empty or undefined roles
	if (!roles || roles.length === 0) {
		return <p>No roles available.</p>;
	}

	return (
		<div className="overflow-x-auto">
			<table className="min-w-full bg-white">
				<thead>
					<tr>
						<th className="py-2 px-4 border-b">ID</th>
						<th className="py-2 px-4 border-b">Name</th>
						<th className="py-2 px-4 border-b">Guard</th>
						<th className="py-2 px-4 border-b">Actions</th>
					</tr>
				</thead>
				<tbody>
					{roles.map((role) => (
						<tr key={role.id} className="text-center">
							<td className="py-2 px-4 border-b">{role.id}</td>
							<td className="py-2 px-4 border-b">{role.name}</td>
							<td className="py-2 px-4 border-b">{role.guard_name}</td>
							<td className="py-2 px-4 border-b">
								<button
									onClick={() => onEditClick(role)}
									className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
								>
									Edit
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default RoleTable;

