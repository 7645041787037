import React from "react";

interface NavigationProps {
    activeTab: string;
    setActiveTab: (tab: string) => void;
    getPatients: () => void;
}

const Navigation: React.FC<NavigationProps> = ({ activeTab, setActiveTab, getPatients }) => {
    return (
        <div className="flex space-x-4 mb-6 border-b">
            <button onClick={() => setActiveTab("Details")} className={`pb-2 ${activeTab === "Details" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                Sample Details
            </button>

            {JSON.parse(sessionStorage.getItem("user") || "{}").roles?.some((role: any) => role.name === "Lab Management") && (
                <button onClick={() => setActiveTab("Lab")} className={`pb-2 ${activeTab === "Lab" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                    Lab
                </button>
            )}
            {JSON.parse(sessionStorage.getItem("user") || "{}").roles?.some((role: any) => role.name === "Lab Management") && (
                <button onClick={() => setActiveTab("Other")} className={`pb-2 ${activeTab === "Other" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                    Other
                </button>
            )}

            {/* this section isnt working at the moment, will need to re design the way it works when i have time
			
			{JSON.parse(sessionStorage.getItem("user") || "{}").roles?.some((role: any) => role.name === "Practitioner") && (
                <button onClick={() => getPatients()} className={`pb-2 ${activeTab === "Patient" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                    Patient Management
                </button>
            )} */}
        </div>
    );
};

export default Navigation;
