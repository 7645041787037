// src/components/icons/SubscriptionIcon.tsx
import React from "react";

const SubscriptionsIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 8v4l2.5 2.5m-7.5-9.5v14m5-2h-1.5a1.5 1.5 0 0 1 0-3H14a3 3 0 0 0 0-6h-2m0 0V4m0 0h6v2H10m0 0H6"
    />
  </svg>
);

export default SubscriptionsIcon;
