import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import UserTable from "../../components/user/UserTable";
import { fetchUsers, User } from "../../api/userApi";
import { getAllRoles, Role } from "../../api/rolesApi";
import Pagination from "../../components/Pagination";
import EditUserModal from "../../components/user/EditUserModal";
import UserButtons from "../../components/user/UserButtons";

const UserManagementPage: React.FC = () => {
    //construct the variables for
    const [users, setUsers] = useState<User[]>([]);
    const [totalUsers, setTotalUsers] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
	const [roles, setRoles] = useState<Role[]>([]); 

    const USERS_PER_PAGE = 30;

	//load the page and set the state
    useEffect(() => {
		//on page load, call the api to get the user data and set the variables
        const loadUsers = async () => {
            setIsLoading(true);
            try {
                const { data, total } = await fetchUsers(currentPage, USERS_PER_PAGE);
                setUsers(data);
                setTotalUsers(total);
                setError(null);
            } catch (err) {
                console.error("Error fetching users:", err);
                setError("Failed to load users.");
            } finally {
                setIsLoading(false);
            }
        };
		const loadRoles = async () => {
            setIsLoading(true);
            try {
                const rolesData = await getAllRoles();
                setRoles(rolesData);
                setError(null);
            } catch (err) {
                console.error("Error fetching roles:", err);
                setError("Failed to load roles.");
            } finally {
                setIsLoading(false);
            }
        };

        loadRoles();
        loadUsers();
    }, [currentPage]);

	const SearchUsers = async (search: string, role: string) => {
		try{
			const { data, total } = await fetchUsers(currentPage, USERS_PER_PAGE, search, role);
			setUsers(data);
			setTotalUsers(total);
			setError(null);
		}catch(err){
			console.error("Error searching patients:", err);
			setError("Failed to search patients.");
		}
	};


	//page functions
    const handleEditClick = (user: User) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setSelectedUser(null);
        setIsModalOpen(false);
    };

    const handleUserUpdate = (updatedUser: User) => {
        // Update user in the list
        setUsers((prevUsers) => prevUsers.map((user) => (user.id === updatedUser.id ? updatedUser : user)));
        handleModalClose();
    };

	
    return (
        <Layout>
            <h1 className="text-2xl font-bold mb-4">User Management</h1>
            {isLoading ? (
                <p>Loading users...</p>
            ) : (
                <>
					<UserButtons searchUsers={SearchUsers} roles={roles}/>
                    <UserTable users={users} onEditClick={handleEditClick} />
                    <Pagination currentPage={currentPage} totalItems={totalUsers} itemsPerPage={USERS_PER_PAGE} onPageChange={setCurrentPage} />
                </>
            )}
            {selectedUser && <EditUserModal isOpen={isModalOpen} onClose={handleModalClose} user={selectedUser} onUserUpdated={handleUserUpdate} />}
        </Layout>
    );
};

export default UserManagementPage;
