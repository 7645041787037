import React from "react";
import { useReactTable, ColumnDef, getCoreRowModel, getSortedRowModel, SortingState } from "@tanstack/react-table";
import { Sample } from "../../../api/labiqApi";

interface SampleTableProps {
    samples: Sample[];
    selectedSamples: number[];
    setSelectedSamples: React.Dispatch<React.SetStateAction<number[]>>;
    onSort: (column: string) => void;
	sortBy: string;
	setSelectedSampleClientId: React.Dispatch<React.SetStateAction<string[]>>;
	selectedSampleClientId: string[];
}

const SampleTable: React.FC<SampleTableProps> = ({ samples, selectedSamples, setSelectedSamples, onSort, sortBy, setSelectedSampleClientId, selectedSampleClientId}) => {
    const columns = React.useMemo<ColumnDef<Sample>[]>(
        () => [
            {
                id: "select",
                header: ({ table }) => (
                    <input
                        type="checkbox"
                        checked={selectedSamples.length === samples.length}
                        onChange={() => {
                            if (selectedSamples.length === samples.length) {
                                setSelectedSamples([]);
                            } else {
                                setSelectedSamples(samples.map((sample) => sample.sampleID));
                            }
                        }}
                        aria-label="Select all samples"
                    />
                ),
                cell: ({ row }) => (
                    <input
                        type="checkbox"
                        checked={selectedSamples.includes(row.original.sampleID)}
                        onChange={() => handleSelectSample(row.original.sampleID, row.original.client_sampleID)}
                        aria-label={`Select sample ${row.original.sampleID}`}
                    />
                ),
            },
            { accessorKey: "sampleID", header: "ID" },
			{ accessorKey: "client_sampleID", header: "Sample ID" },
			{ accessorKey: "batch_client_id", header: "Batch ID" },
            { accessorKey: "client", header: "Client" },
            { accessorKey: "sampleType", header: "Collection" },
            { accessorKey: "QC_date", header: "QC Date" },
            { accessorKey: "concentration", header: "ng/μl" },
            { accessorKey: "OD", header: "260/280" },
            { accessorKey: "OD2", header: "260/230" },
            { accessorKey: "eProtocol", header: "eP" },
            { accessorKey: "status", header: "Status" },
            { accessorKey: "comments", header: "Comments" },
        ],
        [selectedSamples, samples, setSelectedSamples]
    );

    const [sorting, setSorting] = React.useState<SortingState>([]);

    const table = useReactTable({
        data: samples,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: { sorting },
        onSortingChange: setSorting,
    });

    const handleSelectSample = (sampleId: number, sampleClientId: string) => {
        if (selectedSamples.includes(sampleId)) {
            setSelectedSamples(selectedSamples.filter((id) => id !== sampleId));
			setSelectedSampleClientId(selectedSampleClientId.filter((id) => id !== sampleClientId));
        } else {
            setSelectedSamples([...selectedSamples, sampleId]);
			setSelectedSampleClientId([...selectedSampleClientId, sampleClientId]);
        }
    };

    return (
        <table className="min-w-full bg-white border">
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th
                                key={header.id}
                                className="py-2 px-3 border-b text-left cursor-pointer"
                                onClick={() => onSort(header.column.id)}
                            >
                                <>
                                    {header.column.columnDef.header as React.ReactNode}
									{sortBy === header.column.id ? (
										<span className="ml-2">🔽</span>
									) : null}
                                </>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="hover:bg-gray-100">
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className="py-2 px-3 border-b">
                                {cell.column.id === "select" ? (
                                    <input type="checkbox" checked={selectedSamples.includes(row.original.sampleID)} onChange={() => handleSelectSample(row.original.sampleID, row.original.client_sampleID)} aria-label={`Select sample ${row.original.sampleID}`} />
                                ) : (
                                    (cell.renderValue() as React.ReactNode)
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default SampleTable;
