// src/components/inventory/ProductModal.tsx

import React, { useState, useEffect } from "react";
import { Product } from "../../../api/productApi";

interface ProductModalProps {
    isOpen: boolean;
    onClose: () => void;
    product: Product | null;
    onSave: (productData: Partial<Product>) => void;
}

const ProductModal: React.FC<ProductModalProps> = ({ isOpen, onClose, product, onSave }) => {
    const [formData, setFormData] = useState<Partial<Product>>({});

    useEffect(() => {
        setFormData(product || {});
    }, [product]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const numberValue = value === "" ? undefined : parseFloat(value);
        setFormData((prevData) => ({ ...prevData, [name]: numberValue }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSave(formData);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-md w-full max-w-lg">
                <h2 className="text-xl font-bold mb-4">{product ? "Edit Product" : "Add Product"}</h2>
                <form onSubmit={handleSubmit}>
                    {/* Name */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Name</label>
                        <input name="name" type="text" value={formData.name || ""} onChange={handleChange} required className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* Description */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Description</label>
                        <textarea name="description" value={formData.description || ""} onChange={handleChange} className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* SKU */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">SKU</label>
                        <input name="sku" type="text" value={formData.sku || ""} onChange={handleChange} required className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* Barcode */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Barcode</label>
                        <input name="barcode" type="text" value={formData.barcode || ""} onChange={handleChange} className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* Unit Price and Unit Cost */}
                    <div className="mb-4 flex space-x-4">
                        <div className="flex-1">
                            <label className="block text-gray-700 font-medium mb-1">Unit Price</label>
                            <input name="unit_price" type="number" step="0.01" value={formData.unit_price !== undefined ? formData.unit_price : ""} onChange={handleNumberChange} className="w-full border px-3 py-2 rounded" />
                        </div>
                        <div className="flex-1">
                            <label className="block text-gray-700 font-medium mb-1">Unit Cost</label>
                            <input name="unit_cost" type="number" step="0.01" value={formData.unit_cost !== undefined ? formData.unit_cost : ""} onChange={handleNumberChange} className="w-full border px-3 py-2 rounded" />
                        </div>
                    </div>
                    {/* Product Shopify ID */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-1">Shopify Product ID</label>
                        <input name="product_shopify_id" type="number" value={formData.product_shopify_id !== undefined ? formData.product_shopify_id : ""} onChange={handleNumberChange} className="w-full border px-3 py-2 rounded" />
                    </div>
                    {/* Buttons */}
                    <div className="flex justify-end">
                        <button type="button" onClick={onClose} className="mr-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">
                            Cancel
                        </button>
                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                            {product ? "Update" : "Create"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProductModal;
