import React, { Fragment, useState, useEffect } from "react";
import { Dialog, DialogTitle, Transition } from "@headlessui/react";
import {  createSample, updateSample, Sample } from "../../../api/labiqApi";
import { fetchSinglePatientSimple, fetchSamplesPatient, fetchPatientsSimple, assignSampleToPatient, removeSampleFromPatient, Patient } from "../../../api/patientApi";


//import the modal components
import Navigation from "./sampleModalComponents/Navigation";
import SampleDetails from "./sampleModalComponents/SampleDetails";
import SampleLab from "./sampleModalComponents/SampleLab";
import SampleOther from "./sampleModalComponents/SampleOther";
import SamplePatienttab from "./sampleModalComponents/SamplePatientTab";


interface SampleModalProps {
    isOpen: boolean;
    onClose: () => void;
    sample: Sample;
    onFormSubmission: () => void;
}

const SampleModal: React.FC<SampleModalProps> = ({ isOpen, onClose, sample, onFormSubmission }) => {
    const [formData, setFormData] = useState<Partial<Sample>>({});
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [activeTab, setActiveTab] = useState("Details");

	//user pages
	const [users, setUsers] = useState<Patient[]>([]);
	const [linkedUser, setLinkedUser] = useState<number>(0);
	const [linkedUserEmail, setLinkedUserEmail] = useState<string>("");

    //Init on the first render
    useEffect(() => {

        if (isOpen) {
            setFormData({
                sampleID: sample.sampleID,
                client: sample.client,
                jobID: sample.jobID,
                client_runID: sample.client_runID,
                client_sampleID: sample.client_sampleID,
                job_sequence: sample.job_sequence,
                sampleType: sample.sampleType,
                QC_date: sample.QC_date,
                protocol: sample.protocol,
                concentration: sample.concentration,
                OD: sample.OD,
                OD2: sample.OD2,
                eProtocol: sample.eProtocol,
                eP_concentration: sample.eP_concentration,
                eP_OD: sample.eP_OD,
                eP_OD2: sample.eP_OD2,
                dProtocol: sample.dProtocol,
                gel_pic: sample.gel_pic,
                gel_lane: sample.gel_lane,
                OA_date: sample.OA_date,
                OA_plate: sample.OA_plate,
                OA_call_rate: sample.OA_call_rate,
                status: sample.status,
                recollect_notice: sample.recollect_notice,
                reported: sample.reported,
                reported_date: sample.reported_date,
                plated_state: sample.plated_state,
                analysis_requested: sample.analysis_requested,
                turnaround: sample.turnaround,
                date_expected: sample.date_expected,
                volume: sample.volume,
                storage: sample.storage,
                comments: sample.comments,
                QC_notice: sample.QC_notice,
                create_date: sample.create_date,
                lab_location: sample.lab_location,
                available_material: sample.available_material,
                batch_id: sample.batch_id,
            });

        }
        
    }, [isOpen, sample]);

	//load the 
	const getPatients = async () => {
		setActiveTab("Patient");
		try {
			const samplesPatient = await fetchSamplesPatient(sample.client_sampleID);

			if (samplesPatient.length > 0) {
				
				const patient = await fetchSinglePatientSimple(samplesPatient[0].user_email);

				// Ensure the data from fetchSinglePatientSimple is an array, if it's a single patient, wrap it in an array
				const patientData = Array.isArray(patient.data) ? patient.data : [patient.data];
				setUsers(patientData);
				setLinkedUser(patientData[0].id);
				setLinkedUserEmail(patientData[0].email);
			} else {
				
			}
		} catch (err) {
			console.error("Error fetching patients:", err);
			setError("Failed to load patients.");
			setUsers([]); // Set users to empty array on error
		}
	};

	const removeUserFromSample = async () => {
		try {
			await removeSampleFromPatient(linkedUserEmail, sample.client_sampleID);
			//need to 

			setLinkedUser(0);
			setLinkedUserEmail("");
			getPatients();
		} catch (err) {
			console.error("Error removing patient from sample:", err);
			setError("Failed to remove patient from sample.");
		}
	};

    //handle changes to the form
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    //handle changes to the select element
    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    //handle changes to the textarea element
    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    //handle the form submission to update the user details
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            let submittedSample;

			//update standard sample data
            if (sample.sampleID !== 0) {
                submittedSample = await updateSample(sample.sampleID, formData as Sample);
            } else {
                submittedSample = await createSample(formData as Sample);
            }

			console.log("Linked User:", linkedUser);

            // Update assigned user if applicable
            if (linkedUser !== 0) {
                await assignSampleToPatient(linkedUserEmail, submittedSample.client_sampleID);
            }

            onFormSubmission();
        } catch (err) {
            console.error("Error updating user:", err);
            setError("Failed to update user.");
        } finally {
            setIsSubmitting(false);
        }
    };


    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
                <div className="flex items-center justify-center min-h-screen px-4">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black opacity-30" />
                    </Transition.Child>

                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                        <div className="bg-white rounded p-6 z-20 w-1/2">
                            {/* Modal heading */}
                            <DialogTitle className="text-xl font-semibold mb-4">{sample.sampleID === 0 ? "Create New Sample" : `Edit Sample ${formData.client_sampleID}`}</DialogTitle>

                            {/* modal tabs, uses activetab useState react hook to transition state of the tab */}
                            <Navigation activeTab={activeTab} setActiveTab={setActiveTab} getPatients={getPatients}/>

                            <form onSubmit={handleSubmit} className="space-y- ">
                                {/* Details info active tab */}
                                {activeTab === "Details" && (
                                    <SampleDetails formData={formData} handleChange={handleChange} handleSelectChange={handleSelectChange} setError={setError} setFormData={setFormData}/>
                                )}

                                {/* Lab info active tab */}
                                {activeTab === "Lab" && (
                                    <SampleLab formData={formData} handleChange={handleChange} handleSelectChange={handleSelectChange} />
                                )}

                                {/* Other tab */}
                                {activeTab === "Other" && (
                                    <SampleOther formData={formData} handleChange={handleChange} handleTextAreaChange={handleTextAreaChange} />

                                )}

                                {/* Patient management tab */}
                                {JSON.parse(sessionStorage.getItem("user") || "{}").roles?.some((role: any) => role.name === "Practitioner") && activeTab === "Patient" && (
                                    <SamplePatienttab users={users} linkedUser={linkedUser} setLinkedUser={setLinkedUser} setLinkedUserEmail={setLinkedUserEmail} removeUserFromSample={removeUserFromSample} isSubmitting={isSubmitting}  />
                                )}

                                {error && <p className="text-red-500">{error}</p>}
                                <div className="flex justify-end space-x-2">
                                    <button type="button" onClick={onClose} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">
                                        Cancel
                                    </button>
                                    {sample.sampleID !== 0 ? (
                                        <button type="submit" disabled={isSubmitting} className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}>
                                            {isSubmitting ? "Saving..." : "Save Changes"}
                                        </button>
                                    ) : (
                                        <button type="submit" disabled={isSubmitting} className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}>
                                            {isSubmitting ? "Saving..." : "Create Sample"}
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default SampleModal;
