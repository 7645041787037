import React from 'react';
import { User } from '../../api/userApi';

interface UserTableProps {
  users: User[];
  onEditClick: (user: User) => void;
}

const UserTable: React.FC<UserTableProps> = ({ users, onEditClick }) => {
  // Add a check to handle empty or undefined users
  if (!users || users.length === 0) {
    return <p>No users available.</p>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">ID</th>
            <th className="py-2 px-4 border-b">Username</th>
            <th className="py-2 px-4 border-b">Email</th>
            <th className="py-2 px-4 border-b">First Name</th>
            <th className="py-2 px-4 border-b">Last Name</th>
			<th className="py-2 px-4 border-b">Is Active</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id} className="text-center">
              <td className="py-2 px-4 border-b">{user.id}</td>
              <td className="py-2 px-4 border-b">{user.username}</td>
              <td className="py-2 px-4 border-b">{user.email}</td>
              <td className="py-2 px-4 border-b">{user.first_name}</td>
              <td className="py-2 px-4 border-b">{user.last_name}</td>
			  {user.is_active ? <td className="py-2 px-4 border-b" style={{backgroundColor : 'green'}}>True</td> : <td className="py-2 px-4 border-b" style={{backgroundColor : 'red'}}>False</td>}
			  
              <td className="py-2 px-4 border-b">
                <button
                  onClick={() => onEditClick(user)}
                  className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
