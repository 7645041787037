// src/components/icons/PlanManagementIcon.tsx
import React from "react";

const PlanIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4 4h16v6H4V4zM4 14h16v6H4v-6zM9 14v6M15 14v6"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 10V4M16 10V4"
    />
  </svg>
);

export default PlanIcon;
