// ActionButtons.tsx
import React from "react";
import { deleteSample } from "../../../api/labiqApi";
import {  fetchSamplesPatient } from "../../../api/patientApi";


interface SampleTrackingButtonsProps {
    selectedSamples: number[];
	selectedSampleClientId: string[];
    refreshSamples: (searchTerm: string) => void;
    editClick: (sampleID: number) => void;
    newSample: () => void;
    omnibeadUpload: () => void;
	searchTerm: string;
	setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

const SampleTrackingButtons: React.FC<SampleTrackingButtonsProps> = ({ selectedSamples, selectedSampleClientId, refreshSamples, editClick, newSample, omnibeadUpload, searchTerm, setSearchTerm }) => {
    const handleDeleteSamples = async () => {
		// Check if the practitioner has assigned this sample to any patients, 
		// if they have then the sample cant be deleted and throw an error
		for (let i = 0; i < selectedSamples.length; i++) {
			const clientId = selectedSampleClientId[i];
			const id = selectedSamples[i];
			const samplesPatient = await fetchSamplesPatient(clientId.toString());
		
			if (samplesPatient.length === 0) {
				await deleteSample(id);
			} else {
				alert("There is an assigned patient to this sample, and it cannot be deleted.");
			}
		}
        selectedSamples = [];
        refreshSamples("");
    };

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        refreshSamples(e.target.value);
    };

    const handleRecollectNotice = () => {
        // Logic to send recollect notice
    };

    const handleViewHistory = () => {
        // Logic to view sample history
    };

    const handleExportToExcel = () => {
        // Logic to export table data to Excel
    };

    return (
        <div className="flex space-x-2 mb-4">
            <button onClick={newSample} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                New Sample
            </button>
            <button
                onClick={() => editClick(selectedSamples[0])}
                disabled={selectedSamples.length !== 1}
                className={`${selectedSamples.length !== 1 ? "bg-gray-300 text-gray-600 cursor-not-allowed" : "bg-green-500 text-white hover:bg-green-600"} px-4 py-2 rounded`}
            >
                Edit Sample
            </button>
            <button onClick={handleDeleteSamples} disabled={selectedSamples.length === 0} className={`${selectedSamples.length === 0 ? "bg-gray-300 text-gray-600 cursor-not-allowed" : "bg-red-500 text-white hover:bg-red-600"} px-4 py-2 rounded`}>
                Delete Sample
            </button>
            <button
                onClick={handleRecollectNotice}
                disabled={selectedSamples.length === 0}
                className={`${selectedSamples.length === 0 ? "bg-gray-300 text-gray-600 cursor-not-allowed" : "bg-yellow-500 text-white hover:bg-yellow-600"} px-4 py-2 rounded`}
            >
                Send Recollect Notice
            </button>
            <button onClick={handleViewHistory} disabled={selectedSamples.length !== 1} className={`${selectedSamples.length !== 1 ? "bg-gray-300 text-gray-600 cursor-not-allowed" : "bg-purple-500 text-white hover:bg-purple-600"} px-4 py-2 rounded`}>
                View History
            </button>

            <button onClick={handleExportToExcel} className="bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600">
                Export to Excel
            </button>
            {false && (
                <button onClick={omnibeadUpload} className="bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600">
                    Upload Omnibead
                </button>
            )}

            <div className="justify-end">
                <input type="text" placeholder="Search by SampleId..." value={searchTerm} onChange={handleSearchChange} className="border px-4 py-2 rounded" />
                <button onClick={() => refreshSamples("")} className="px-4 py-2 bg-gray-200 rounded opacity-100 hover:bg-gray-300">
                    Reset
                </button>
            </div>
        </div>
    );
};

export default SampleTrackingButtons;
