// src/api/companyApi.ts

import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_AUTH_SERVER;

export interface Company {
    id: number;
    name: string;
    address: string | null;
    email: string;
    phone: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export const fetchCompanies = async (): Promise<Company[]> => {
    const response = await axios.get(`${API_BASE_URL}/admin/companies`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return response.data;
};

export const fetchSingleCompany = async (companyId: string): Promise<Company> => {
	const response = await axios.get(`${API_BASE_URL}/admin/companies/${companyId}`, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};